import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import API from "../../api"
import {
  Col,
  Input,
  Row,
  Label,
  Form,
  Button,
  Container,
  Card,
  CardBody,
  CardTitle,
  Spinner,
} from "reactstrap"
// Toastify
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"
//i18n
import { withTranslation } from "react-i18next"
import Select from "react-select"

const EditSpecialite = props => {
  const [specialite, setSpecialite] = useState("")
  const [cycle, setCycle] = useState("")
  const [diplome, setDiplome] = useState("")
  const [selectNiveau, setSelectNiveau] = useState([])
  const [loading, setLoading] = useState(false)
  const [departement, setDepartement] = useState([]);
  const [selectDepartement, setSelectDepartement] = useState("");

  useEffect(async () => {
    var url = window.location.href
    var array = url.split("=")
    var id = array[1]
    //   Département
    const resDe = await API.get("departement/select").then(resDe => {
      setDepartement(resDe.data.Departement)
    })
    const res = await API.post("specialite/get_by_id", { id }).then(res => {
      setSpecialite(res.data.Specialite.name)
      setCycle(res.data.Specialite.cycle.label)
      setDiplome(res.data.Specialite.diplome.label)
      setSelectNiveau(res.data.Specialite.niveau)
      setSelectDepartement(res.data.Specialite.departement)
      setLoading(true)
    })
  }, [])

  const edit = async () => {
    var url = window.location.href
    var array = url.split("=")
    var id = array[1]
    if (specialite != "" && selectDepartement!="") {
      const res = await API.post("specialite/update", {
        id: id,
        name: specialite,
        departement_id:selectDepartement.value
      })
        .then(res => {
          props.history.push("/Specialite")
        })
        .catch(() => {
          toast.error("⛔  Problème lors de l'insertion !", {
            containerId: "A",
          })
        })
    } else {
      toast.error("⛔ Spécialité obligatoire", {
        containerId: "A",
      })
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Card>
            <CardBody>
              {loading ? (
                <Row>
                  <Form>
                    <Row>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">Cycle</Label>
                          <Input
                              lg="3"
                              className="form-control glyphicon glyphicon-star"
                              type="text"
                              value={cycle}
                              disabled={true}
                          />
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">Département</Label>
                          <Select
                              value={selectDepartement}
                              options={departement}
                              isSearchable={true}
                              onChange={e => setSelectDepartement(e)}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>

                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Diplome
                          </Label>
                          <Input
                            lg="3"
                            className="form-control glyphicon glyphicon-star"
                            type="text"
                            value={diplome}
                            disabled={true}
                          />
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Niveau/Semestre
                          </Label>
                          <Input
                              lg="3"
                              className="form-control glyphicon glyphicon-star"
                              type="text"
                              value={selectNiveau}
                              disabled={true}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Spécialité
                          </Label>
                          <Input
                            lg="3"
                            type="text"
                            className="form-control"
                            placeholder="Spécialité"
                            value={specialite}
                            onChange={e => setSpecialite(e.target.value)}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Form>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    {" "}
                    <Col lg="6">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                        className="text-center mt-4"
                      >
                        <Button
                          type="button"
                          color="warning"
                          className="btn btn-warning  mb-2 me-2"
                          onClick={() => props.history.push("/Specialite")}
                        >
                          Annuler
                        </Button>
                      </div>
                    </Col>
                    <Col lg="6">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                        className="text-center mt-4"
                      >
                        <button
                          type="button"
                          className="btn btn-primary "
                          onClick={edit}
                        >
                          CONFIRMER
                        </button>
                      </div>
                    </Col>
                  </div>
                </Row>
              ) : (
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {" "}
                    <Spinner type="grow" className="ms-6" color="primary" />
                  </div>
                  <h4
                    style={{ textAlign: "center", marginTop: "2%" }}
                    className="ms-6"
                  >
                    {" "}
                    {props.t("Chargement")} ...{" "}
                  </h4>
                </div>
              )}
            </CardBody>
          </Card>
          <ToastContainer
            transition={Slide}
            enableMultiContainer
            containerId={"A"}
            position={toast.POSITION.TOP_RIGHT}
            autoClose={2500}
          />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(EditSpecialite)
EditSpecialite.propTypes = {
  history: PropTypes.object,
  back: PropTypes.object,
  t: PropTypes.any,
  setSection: PropTypes.object,
}
