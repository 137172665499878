import React, { Fragment, useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
  Button,
  Col,
  Label,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Spinner,
  Container,
  Card,
  CardBody,
  Input,
} from "reactstrap"
// Toastify
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"
import API from "../../api"
import Select from "react-select"

const AddScheduleEnseignant = props => {
  const [loading, setLoading] = useState(false)
  const [loadingAdd, setLoadingAdd] = useState(true)
  const [enseignantId, setEnseignantId] = useState("")
  const [type, setType] = useState([])
  const [selectType, setSelectType] = useState([])
  const [etatQuanzaine, setEtatQuanzaine] = useState(0)
  const [matiere, setMatiere] = useState([])
  const [selectMatiere, setSelectMatiere] = useState("")
  const [salle, setSalle] = useState([])
  const [selectSalle, setSelectSalle] = useState("")
  const [groupe, setGroupe] = useState([])
  const [selectGroupe, setSelectGroupe] = useState("")
  const [quarts, setQuarts] = useState([])
  const [jours, setJours] = useState([])
  const [modal, setModal] = useState(false)
  const [jourId, setJourId] = useState("")
  const [quartId, setQuartId] = useState("")
  const [enseignantNom, setEnseignantNom] = useState("")
  const [voeuxJour, setVoeuxJour] = useState([])
  const [modalDelete, setModalDelete] = useState(false)
  const [element, setElement] = useState("")
  const [quan, setQuan] = useState(0)
  const [nbrHeure, setNbrHeure] = useState("")
  const [modalUpdate, setModalUpdate] = useState("")
  const [seance, setSeance] = useState("")
  const [salleId, setSalleId] = useState("")
  const [semestre_id, setSemestre_id] = useState("")
  const [modalLigne, setModalLigne] = useState(false)
  const [indexLigne, setIndexLigne] = useState("")
  const [array, setArray] = useState([
    {
      matiere_id: "",
      group_id: "",
      salle_id:""
    },
  ])
  const [tousSalle, setTousSalle] = useState(2)
  var userAuthScolarite = JSON.parse(localStorage.getItem("userAuthScolarite"))
  var annee_id = userAuthScolarite.user.annee_id
  useEffect(async () => {
    var url = window.location.href
    var array = url.split("=")
    var id_semestre = array[1]
    var array1 = id_semestre.split("/")
    var id = array1[0]
    var semestre_id = array1[1]
    setEnseignantId(id)
    setSemestre_id(semestre_id)
    var userAuthScolarite = JSON.parse(localStorage.getItem("userAuthScolarite"))
    var annee_id = userAuthScolarite.user.annee_id
    const resE = await API.post("emploi/get_by_id", {
      enseignant_id: id,
      semestre_id: semestre_id,
      annee_id:annee_id
    }).then(resE => {
      setQuarts(resE.data.emploi[0].quarts)
      setJours(resE.data.emploi[0].jours)
      setLoading(true)
    })
    const res = await API.post("emploi/get_type_matiere", {
      annee_id:annee_id,
      enseignant_id: id,
      semestre_id: semestre_id,
    }).then(res => {
      setType(res.data.emploi)
    })
    const resS = await API.post("fiche/fiche_jour", {
      semestre_id: semestre_id,
      enseignant_id: id,
      annee_id:annee_id
    }).then(resS => {
      setEnseignantNom(resS.data.FicheVoeau.enseignant)
      setVoeuxJour(resS.data.FicheVoeau.jour)
    })
  }, [])
  const addLigne = () => {
    let element = {
      matiere_id: "",
      group_id: "",
      salle_id:""
    }
    setArray([...array, element])
  }
  const toggleLigne = indexOfLigne => {
    setModalLigne(!modalLigne)
    setIndexLigne(indexOfLigne)
  }

  const toggleDeleteLigne = () => {
    array.splice(indexLigne, 1)
    setModalLigne(false)
  }

  const open = element => {
    setModalDelete(!modalDelete)
    setElement(element)
  }

  const openModal = async (elle, element) => {
    setModalUpdate(true)
    setSalleId(elle.id_salle)
    setSeance(elle)
    var userAuthScolarite = JSON.parse(localStorage.getItem("userAuthScolarite"))
    var annee_id = userAuthScolarite.user.annee_id
    // const resG = await API.post("emploi/get_group", {
    //   semestre_id: semestre_id,
    //   quart_id: elle.id_quart,
    //   jour_id: elle.id_jour,
    //   type: elle.type,
    //   matiere_id: elle.id_matiere,
    //   quan: elle.quan,
    //   enseignant_id: enseignantId,
    //   annee_id:annee_id
    // }).then(resG => {
    //   setGroupe(resG.data.groups)
    // })
    const resS = await API.post("emploi/get_salle_update", {
      seance_id: elle.id_seance
    }).then(resS => {
      setSalle(resS.data.salles)
    })
  }

  const openModal1 = async () => {
    setModalUpdate(false)
    setSelectSalle("")
    setSelectGroupe("")
  }

  const toggle = (element, elem) => {
    setSelectType("")
    setEtatQuanzaine(0)
    setMatiere([])
    setSelectMatiere("")
    setSalle([])
    setSelectSalle("")
    setGroupe([])
    setSelectGroupe("")
    setNbrHeure("")
    setModal(!modal)
    setLoadingAdd(true)
    var jour_id = elem
    var quart_id = element.id_quart
    setJourId(jour_id)
    setQuartId(quart_id)
    setQuan(0)
    setArray([
      {
        matiere_id: "",
        group_id: "",
      },
    ])
    setTousSalle(2)
   }

  const toggle1 = (element, elem) => {
    setSelectType("")
    setEtatQuanzaine(1)
    setMatiere([])
    setSelectMatiere("")
    setSalle([])
    setSelectSalle("")
    setGroupe([])
    setSelectGroupe("")
    setNbrHeure("")
    setModal(!modal)
    setLoadingAdd(true)
    var jour_id = elem
    var quart_id = element.id_quart
    setJourId(jour_id)
    setQuartId(quart_id)
    setQuan(1)
    setArray([
      {
        matiere_id: "",
        group_id: "",
        salle_id
      },
    ])
    setTousSalle(2)
  }

  const getMatiere = async (e, index) => {
    setSelectType(e)
    setSelectMatiere("")
    setSelectSalle("")
    setSelectGroupe("")
    setNbrHeure("")
    var userAuthScolarite = JSON.parse(localStorage.getItem("userAuthScolarite"))
    var annee_id = userAuthScolarite.user.annee_id
    const res = await API.post("emploi/get_matiere", {
      type: e.value,
      semestre_id: semestre_id,
      enseignant_id: enseignantId,
      annee_id:annee_id
    }).then(res => {
      setMatiere(res.data.matieres)
    })

  }

  const getGroupe = async (e, index) => {
    setSelectSalle(e)
    setArray(
        array.map((el, id) =>
            id === index ? Object.assign(el, { salle_id: e }) : el
        )
    )
    var userAuthScolarite = JSON.parse(localStorage.getItem("userAuthScolarite"))
    var annee_id = userAuthScolarite.user.annee_id
    const resG = await API.post("emploi/get_group", {
      semestre_id: semestre_id,
      quart_id: quartId,
      jour_id: jourId,
      quan: etatQuanzaine,
      matiere_id: selectMatiere.value,
      type: selectType.value,
      enseignant_id: enseignantId,
      annee_id:annee_id
    }).then(resG => {
      setGroupe(resG.data.groups)
    })
  }

  const getGroupe1 = async (e, index) => {
    setArray(
      array.map((el, id) =>
        id === index ? Object.assign(el, { matiere_id: e }) : el
      )
    )
    setSelectMatiere(e)
    var userAuthScolarite = JSON.parse(localStorage.getItem("userAuthScolarite"))
    var annee_id = userAuthScolarite.user.annee_id
    setSelectGroupe("")
    setSelectSalle("")
    const resS = await API.post("emploi/get_salle", {
      semestre_id: semestre_id,
      quart_id: quartId,
      jour_id: jourId,
      quan: etatQuanzaine,
      annee_id:annee_id,
      matiere_id: e.value,
      type: selectType.value,
    }).then(resS => {
      setSalle(resS.data.salles)
    })
    const resG = await API.post("emploi/get_group", {
      semestre_id: semestre_id,
      quart_id: quartId,
      jour_id: jourId,
      quan: etatQuanzaine,
      matiere_id: e.value,
      type: selectType.value,
      enseignant_id: enseignantId,
      annee_id:annee_id
    }).then(resG => {
      setGroupe(resG.data.groups)
    })
    const res = await API.post("seance/get_heure_by_type", {
      matiere_id: e.value,
      type: selectType.value,
    }).then(res => {
      setArray(
        array.map((el, id) =>
          id === index ? Object.assign(el, { nbrHeure: res.data.nb_heure }) : el
        )
      )
      setNbrHeure(res.data.nb_heure)
      // if (res.data.nb_heure == 10.5) {
      //   setEtatQuanzaine(1)
      // }
    })

  }

  const setQuanzaine = async (event, index) => {
      if (event.target.checked === true) {
      setEtatQuanzaine(1)
      setSelectGroupe("")
      setSelectSalle("")

     } else {
      setEtatQuanzaine(0)
      setSelectGroupe("")
      setSelectSalle("")
     }
  }

  const addGroupe = (e, index) => {
    setSelectGroupe(e)
    setArray(
      array.map((el, id) =>
        id === index ? Object.assign(el, { group_id: e }) : el
      )
    )
  }

  const hover = (index, indice, ind) => {
    document.getElementById(
      "hover_td" + index + indice + ind
    ).style.transition = `0.7s ease all`
    document.getElementById("hover_td" + index + indice + ind).style.boxShadow =
      "rgb(0 0 0 / 20%) 0px 0px 20px 1px"
    document.getElementById("hover_td" + index + indice + ind).style.scale =
      "(0.9px)"
  }

  const leave = (index, indice, ind) => {
    document.getElementById("hover_td" + index + indice + ind).style.boxShadow =
      "none"
    document.getElementById("hover_td" + index + indice + ind).style.scale =
      "none"
    document.getElementById(
      "hover_td" + index + indice + ind
    ).style.transition = `none`
  }

  const save = async () => {
    if (selectType == "") {
      toast.error("⛔ Type obligatoire !", {
        containerId: "A",
      })
    } else if (selectMatiere == "") {
      toast.error("⛔ Matière obligatoire !", {
        containerId: "A",
      })
    }  else if (selectGroupe != "") {
      var userAuthScolarite = JSON.parse(localStorage.getItem("userAuthScolarite"))
      var annee_id = userAuthScolarite.user.annee_id
      setLoadingAdd(false)
      const res = await API.post("seance/add", {
        quart_id: quartId,
        jour_id: jourId,
        enseignant_id: enseignantId,
        type: selectType.value,
        quan: etatQuanzaine,
        salle_id: selectSalle.value,
        array: array,
        semestre_id: semestre_id,
        annee_id:annee_id
      }).then(res => {
        setLoadingAdd(true)
        if(res.data.errNum == 403){
          toast.error("⛔ Le Groupe n'est pas disponible dans cette seance !", {
            containerId: "A",
          })
        }else{
          const resE = API.post("emploi/get_by_id", {
            enseignant_id: enseignantId,
            semestre_id: semestre_id,
            annee_id:annee_id
          }).then(resE => {
            setQuarts(resE.data.emploi[0].quarts)
            setJours(resE.data.emploi[0].jours)
            setModal(false)
          })
        }

      })
    } else {
      toast.error("⛔ Groupe obligatoire !", {
        containerId: "A",
      })
    }
  }

  const edit = async () => {
    if (selectSalle == "") {
      var salle_id = salleId
    } else {
      var salle_id = selectSalle.value
    }
    var userAuthScolarite = JSON.parse(localStorage.getItem("userAuthScolarite"))
    var annee_id = userAuthScolarite.user.annee_id
    const res = await API.post("seance/update", {
      seance_id: seance.id_seance,
      salle_id: salle_id
    }).then(res => {
      const resE = API.post("emploi/get_by_id", {
        enseignant_id: enseignantId,
        semestre_id: semestre_id,
        annee_id:annee_id
      }).then(resE => {
        setQuarts(resE.data.emploi[0].quarts)
        setJours(resE.data.emploi[0].jours)
        setModalUpdate(!modalUpdate)
      })
    })
  }

  const deleteSeance = async () => {
    var userAuthScolarite = JSON.parse(localStorage.getItem("userAuthScolarite"))
    var annee_id = userAuthScolarite.user.annee_id
    const res = await API.post("seance/delete", {
      array: element,
    }).then(res => {
      const resE = API.post("emploi/get_by_id", {
        enseignant_id: enseignantId,
        semestre_id: semestre_id,
        annee_id:annee_id
      }).then(resE => {
        setQuarts(resE.data.emploi[0].quarts)
        setJours(resE.data.emploi[0].jours)
        setModalDelete(!modalDelete)
      })
    })
  }
  const TousSalle = async () => {
    setSelectSalle("")

    setTousSalle(1)
    const res = await API.get("salle/select").then(res => {
      setSalle(res.data.Salle)
    })
  }
  const filterSalle = async () => {
    if(selectMatiere !=""){
      setTousSalle(2)
      const resS = await API.post("emploi/get_salle", {
        semestre_id: semestre_id,
        quart_id: quartId,
        jour_id: jourId,
        quan: etatQuanzaine,
        annee_id:annee_id,
        matiere_id: selectMatiere.value,
        type: selectType.value,
      }).then(resS => {
        setSalle(resS.data.salles)
      })
    }else{
      setTousSalle(1)
      toast.error("⛔ Matière est obligatoire !!", {
        containerId: "A",
      })
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Card>
            <CardBody>
              {loading ? (
                <Row>
                  <div
                    style={{
                      display: "flex",
                      fontSize: "13px",
                    }}
                  >
                    <span style={{ paddingRight: "4px" }}>Enseignant:</span>
                    <span style={{ fontWeight: "bold" }}>{enseignantNom}</span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      fontSize: "13px",
                    }}
                  >
                    <span style={{ paddingRight: "4px" }}>Voeux du jours:</span>
                    {voeuxJour.map((el, index) => (
                      <span
                        key={index}
                        style={{ fontWeight: "bold", paddingLeft: "5px" }}
                      >
                        {el.name} ,
                      </span>
                    ))}
                  </div>
                  <div>
                    <div className="table-responsive mt-4">
                      <table className="table table-bordered text-center">
                        <thead className="table table-light">
                          <tr>
                            <th></th>
                            {quarts.map(el => (
                              <th
                                key={el.id_quart}
                                style={{ whiteSpace: "nowrap" }}
                              >
                                {el.label_quart}
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {jours.map((elem, index) => (
                            <tr key={elem.id_jour}>
                              <td style={{ fontWeight: "bold" }}>
                                {elem.label_jour}
                              </td>
                              {elem.seances.map((element, indice) =>
                                element.type != null ? (
                                  element.quan == 1 ? (
                                    <td
                                      key={indice}
                                      style={{ padding: "0px" }}
                                      className="td-emploi"
                                    >
                                      {element.options.map((elle, ind) => (
                                        <div key={ind}>
                                          <div>
                                            <div
                                              onDoubleClick={() =>
                                                openModal(elle, element)
                                              }
                                              id={
                                                "hover_td" +
                                                index +
                                                indice +
                                                ind
                                              }
                                              onMouseEnter={() =>
                                                hover(index, indice, ind)
                                              }
                                              onMouseLeave={() =>
                                                leave(index, indice, ind)
                                              }
                                              style={{
                                                padding: "2px 2px 2px 2px",
                                                cursor: "pointer",
                                              }}
                                            >
                                              <div
                                                onClick={() => open(elle)}
                                                className="icon_remove"
                                              >
                                                <i
                                                  style={{
                                                    color: "red",
                                                    cursor: "pointer",
                                                  }}
                                                  className="far fa-trash-alt"
                                                ></i>
                                              </div>
                                              <p
                                                style={{
                                                  textAlign: "center",
                                                }}
                                              >
                                                {elle.label_matiere}
                                              </p>
                                              <p
                                                style={{
                                                  textAlign: "center",
                                                }}
                                              >
                                                {elle.label_group}
                                              </p>
                                              <p
                                                style={{
                                                  textAlign: "center",
                                                }}
                                              >
                                                {elle.label_salle}
                                              </p>
                                              {elle.type == 1 ? (
                                                <div className="div-icon">
                                                  <span className="icon-cour">
                                                    C
                                                  </span>
                                                  <div className="icon_quan"></div>
                                                </div>
                                              ) : elle.type == 2 ? (
                                                <div className="div-icon">
                                                  <span className="icon-td">
                                                    TD
                                                  </span>
                                                  <div className="icon_quan"></div>
                                                </div>
                                              ) : elle.type == 3 ? (
                                                <div className="div-icon">
                                                  <span className="icon-tp">
                                                    TP
                                                  </span>
                                                  <div className="icon_quan"></div>
                                                </div>
                                              ) : (
                                                <div className="div-icon">
                                                  <span className="icon-autre">
                                                    CI
                                                  </span>
                                                  <div className="icon_quan"></div>
                                                </div>
                                              )}
                                            </div>
                                            {element.options.length == 1 ? (
                                              <div style={{ padding: "5% 0%" }}>
                                                <button
                                                  onClick={() =>
                                                    toggle1(
                                                      element,
                                                      elem.id_jour
                                                    )
                                                  }
                                                  type="button"
                                                  className="btn btn-info btn-link btn-just-icon btn-sm text-white"
                                                  title="Ajouter Séance"
                                                >
                                                  <i className="fas fa-plus"></i>
                                                </button>
                                              </div>
                                            ) : null}
                                          </div>
                                        </div>
                                      ))}
                                    </td>
                                  ) : (
                                    <td
                                      style={{ padding: "0px" }}
                                      className="td-emploi"
                                    >
                                      {element.options.map((elle, ind) => (
                                        <div
                                          className=""
                                          key={indice}
                                          onDoubleClick={() =>
                                            openModal(elle, element)
                                          }
                                          id={"hover_td" + index + indice + ind}
                                          onMouseEnter={() =>
                                            hover(index, indice, ind)
                                          }
                                          onMouseLeave={() =>
                                            leave(index, indice, ind)
                                          }
                                          style={{
                                            padding: "2px 2px 2px 2px",
                                            cursor: "pointer",
                                            borderBottom:
                                              ind == 0 &&
                                              element.options.length > 1
                                                ? "1px solid #eff2f7"
                                                : null,
                                          }}
                                        >
                                          <div
                                            onClick={() => open(elle)}
                                            className="icon_remove"
                                          >
                                            <i
                                              style={{
                                                color: "red",
                                                cursor: "pointer",
                                              }}
                                              className="far fa-trash-alt"
                                            ></i>
                                          </div>
                                          <p
                                            style={{
                                              textAlign: "center",
                                            }}
                                          >
                                            {elle.label_matiere}
                                          </p>
                                          <p
                                            style={{
                                              textAlign: "center",
                                            }}
                                          >
                                            {elle.label_group}
                                          </p>
                                          <p
                                            style={{
                                              textAlign: "center",
                                            }}
                                          >
                                            {elle.label_salle}
                                          </p>
                                          {elle.rattrapage == 1 ? (
                                            <div className="div-icon">
                                              <span className="icon-rattrapage">
                                                R
                                              </span>
                                            </div>
                                          ) : null}
                                          {elle.type == 1 ? (
                                            <div className="div-icon">
                                              <span className="icon-cour">
                                                C
                                              </span>
                                            </div>
                                          ) : elle.type == 2 ? (
                                            <div className="div-icon">
                                              <span className="icon-td">
                                                TD
                                              </span>
                                            </div>
                                          ) : elle.type == 3 ? (
                                            <div className="div-icon">
                                              <span className="icon-tp">
                                                TP
                                              </span>
                                            </div>
                                          ) : (
                                            <div className="div-icon">
                                              <span className="icon-autre">
                                                CI
                                              </span>
                                            </div>
                                          )}
                                        </div>
                                      ))}
                                    </td>
                                  )
                                ) : (
                                  <td className="td-emploi">
                                    <div className="div-emploi">
                                      <button
                                        onClick={() =>
                                          toggle(element, elem.id_jour)
                                        }
                                        type="button"
                                        className="btn btn-info btn-link btn-just-icon btn-sm text-white"
                                        title="Ajouter Séance"
                                      >
                                        <i className="fas fa-plus"></i>
                                      </button>
                                    </div>
                                  </td>
                                )
                              )}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <div
                        className="mt-4"
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Button
                          type="button"
                          color="warning"
                          className="btn btn-warning  mb-2 me-2"
                          onClick={() =>
                            props.history.push("/SchedulesEnseignants")
                          }
                        >
                          Annuler
                        </Button>
                      </div>
                    </div>
                  </div>
                </Row>
              ) : (
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {" "}
                    <Spinner type="grow" className="ms-6" color="primary" />
                  </div>
                  <h4
                    style={{ textAlign: "center", marginTop: "2%" }}
                    className="ms-6"
                  >
                    {" "}
                    {"Chargement"} ...{" "}
                  </h4>
                </div>
              )}
            </CardBody>
          </Card>
          <ToastContainer
            transition={Slide}
            enableMultiContainer
            containerId={"A"}
            position={toast.POSITION.TOP_RIGHT}
            autoClose={2500}
          />
        </Container>
      </div>
      <Modal
        style={{
          maxWidth: "700px",
          width: "100%",
        }}
        isOpen={modal}
        toggle={toggle}
        centered={true}
      >
        <ModalHeader
          style={{
            width: "100% !important",
            textAlign: "center !important",
          }}
          toggle={toggle}
          tag="h4"
        >
          Ajouter Séance
        </ModalHeader>
        <ModalBody>
          {loadingAdd ? (
            <div>
              {array.map((element, index) => (
                  <Fragment key={index}>
                    <Row>
                      <Col lg="4">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">Type</Label>
                          <Select
                              options={type}
                              isSearchable={true}
                              value={selectType}
                              onChange={e => getMatiere(e, index)}
                          />
                        </div>
                      </Col>
                      <Col lg="2">
                        <div className="form-check mt-5">
                          <Input
                              type="checkbox"
                              className="form-check-Input"
                              id="formrow-customCheck"
                              checked={
                                  etatQuanzaine == 1
                              }
                              disabled={quan == 1}
                              onClick={e => setQuanzaine(e, index)}
                          />
                          <Label
                              className="form-check-Label"
                              htmlFor="formrow-customCheck"
                          >
                            Par quanzaine
                          </Label>
                        </div>
                      </Col>
                      <Col lg="4">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">Matiere</Label>
                          <Select
                              options={matiere}
                              isSearchable={true}
                              // value={selectMatiere}
                              value={element.matiere_id}
                              onChange={e => getGroupe1(e, index)}
                          />
                        </div>
                      </Col>
                      <Col lg="2">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Nbr heure
                          </Label>
                          <Input
                              lg="3"
                              className="form-control"
                              placeholder="nbr heure"
                              type="text"
                              value={nbrHeure}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <div className="mb-3">
                          <Label className="d-block mb-3">Choix des salles :</Label>
                          <div className="form-check form-check-inline">
                            <Input
                                type="radio"
                                id="customRadioInline1"
                                name="customRadioInline1"
                                className="form-check-input"
                                checked={tousSalle === 1}
                                onChange={e => TousSalle(1)}
                                disabled={quan != 1}
                                // disabled={isReadOnly}
                            />
                            <Label
                                className="form-check-label"
                                htmlFor="customRadioInline1"
                            >
                              Tous Salles
                            </Label>
                          </div>
                          &nbsp; &nbsp; &nbsp;&nbsp;
                          <div className="form-check form-check-inline">
                            <Input
                                type="radio"
                                id="customRadioInline2"
                                name="customRadioInline1"
                                className="form-check-input"
                                checked={tousSalle === 2}
                                onChange={e => filterSalle(2)}
                                disabled={quan != 1}
                                // disabled={isReadOnly}
                            />
                            <Label
                                className="form-check-label"
                                htmlFor="customRadioInline2"
                            >
                              Filtrer Salles
                            </Label>
                          </div>
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">Salle</Label>
                          <Select
                              options={salle}
                              isSearchable={true}
                              // value={selectSalle}
                              value={element.salle_id}
                              onChange={e => getGroupe(e, index)}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>

                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">Groupe</Label>
                          <Select
                              options={groupe}
                              isSearchable={true}
                              isMulti={true}
                              // value={selectGroupe}
                              value={element.group_id}
                              onChange={e => addGroupe(e, index)}
                          />
                        </div>
                      </Col>
                    </Row>
                    <div style={{display: "flex", justifyContent: "center"}}>
                      <i
                          style={{
                            color: "#a0ceb8",
                            cursor: "pointer",
                            paddingTop: "5px",
                          }}
                          className="fas fa-plus font-size-18"
                          onClick={addLigne}
                      />
                      {array.length > 1 ? (
                          <i
                              style={{color: "#cc0000"}}
                              className="mdi mdi-delete font-size-18"
                              id="deletetooltip"
                              onClick={() => toggleLigne(index)}
                          />
                      ) : null}
                    </div>
                  </Fragment>
              ))}
              <Row>
                <div style={{display: "flex", justifyContent: "center"}}>
                  {" "}
                  <Col lg="6">
                    <div className="text-center mt-4">
                      <button
                          type="button"
                          className="btn btn-primary "
                          onClick={save}
                      >
                        CONFIRMER
                      </button>
                    </div>
                  </Col>
                </div>
              </Row>
            </div>
          ) : (
              <div>
                <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
              >
                {" "}
                <Spinner type="grow" className="ms-6" color="primary" />
              </div>
              <h4
                style={{ textAlign: "center", marginTop: "2%" }}
                className="ms-6"
              >
                {" "}
                {"Chargement"} ...{" "}
              </h4>
            </div>
          )}
        </ModalBody>
      </Modal>
      <Modal isOpen={modalDelete} toggle={open} centered={true}>
        <ModalHeader toggle={open} tag="h4">
          {" Suppression d'une seance"}{" "}
        </ModalHeader>
        <ModalBody>
          <div
            style={{
              fontFamily: "Montserrat, sans-serif",
              FontSize: "14px",
              FontWeight: "700",
              LineHeight: "18.375px",
            }}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              <p>Voulez-vous supprimer cette seance ?</p>
            </div>
            <div
              className="hvr-push"
              style={{
                display: "flex",
                justifyContent: "space-around",
                marginBottom: "15px",
              }}
            >
              <div>
                <Button
                  onClick={deleteSeance}
                  color="info"
                  className="btn-rounded "
                >
                  <i
                    style={{ color: "white" }}
                    className="far fa-thumbs-up"
                  ></i>
                  Oui
                </Button>
              </div>
              <div>
                <Button onClick={open} color="danger" className="btn-rounded ">
                  <i
                    style={{ color: "white" }}
                    className="far fa-thumbs-down"
                  ></i>
                  Non
                </Button>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <Modal
        style={{
          maxWidth: "300px",
          width: "100%",
        }}
        isOpen={modalUpdate}
        toggle={openModal1}
        centered={true}
      >
        <ModalHeader
          style={{
            width: "100% !important",
            textAlign: "center !important",
          }}
          toggle={openModal1}
          tag="h4"
        >
          Update Séance
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col lg="12">
              <div className="mb-6">
                <Label for="basicpill-firstname-input1">Salle</Label>
                <Select
                  options={salle}
                  isSearchable={true}
                  value={selectSalle}
                  onChange={e => setSelectSalle(e)}
                />
              </div>
            </Col>
            {/*<Col lg="6">*/}
            {/*  <div className="mb-3">*/}
            {/*    <Label for="basicpill-firstname-input1">Groupe</Label>*/}
            {/*    <Select*/}
            {/*      options={groupe}*/}
            {/*      isSearchable={true}*/}
            {/*      value={selectGroupe}*/}
            {/*      onChange={e => setSelectGroupe(e)}*/}
            {/*    />*/}
            {/*  </div>*/}
            {/*</Col>*/}
          </Row>
          <Row>
            <div style={{ display: "flex", justifyContent: "center" }}>
              {" "}
              <Col lg="6">
                <div className="text-center mt-4">
                  <button
                    type="button"
                    className="btn btn-primary "
                    onClick={edit}
                  >
                    CONFIRMER
                  </button>
                </div>
              </Col>
            </div>
          </Row>
        </ModalBody>
      </Modal>
      <Modal isOpen={modalLigne} toggle={toggleLigne} centered={true}>
        <ModalHeader toggle={toggleLigne} tag="h4">
          {" Suppression d'une ligne"}{" "}
        </ModalHeader>
        <ModalBody>
          <div
            style={{
              fontFamily: "Montserrat, sans-serif",
              FontSize: "14px",
              FontWeight: "700",
              LineHeight: "18.375px",
            }}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              <p>Voulez-vous supprimer cette ligne ?</p>
            </div>
            <div
              className="hvr-push"
              style={{
                display: "flex",
                justifyContent: "space-around",
                marginBottom: "15px",
              }}
            >
              <div>
                <Button
                  onClick={toggleDeleteLigne}
                  color="info"
                  className="btn-rounded "
                >
                  <i
                    style={{ color: "white" }}
                    className="far fa-thumbs-up"
                  ></i>
                  Oui
                </Button>
              </div>
              <div>
                <Button
                  onClick={toggleLigne}
                  color="danger"
                  className="btn-rounded "
                >
                  <i
                    style={{ color: "white" }}
                    className="far fa-thumbs-down"
                  ></i>
                  Non
                </Button>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default AddScheduleEnseignant
AddScheduleEnseignant.propTypes = {
  history: PropTypes.object,
}
