import React, { useEffect, useState } from "react";
import Select from "react-select";
import API from "../../api";
import PropTypes from "prop-types";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Slide } from "react-toastify";
import { withTranslation } from "react-i18next";
import { Button, Col, Label, Row } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import "rc-checkbox/assets/index.css";
import Switch from "react-switch";
const CloturerBaseDonnee = (props) => {
    const [annee, setAnnee] = useState("");
    const [isActive, setIsActive] = useState(false); // Initialize to false to have the switch off by default
    const [disabledAction, setDisabledAction] = useState(false);
    var userAuthScolarite = JSON.parse(localStorage.getItem("userAuthScolarite"));
    var annee_id = userAuthScolarite.user.annee_id;
    var user_id = userAuthScolarite.user.id;

    useEffect(() => {
        const fetchAnnee = async () => {
            const resD = await API.post("annee/get_by_id", { id: annee_id });
            setAnnee(resD.data.Annee.annee_scolaire);
        };
        fetchAnnee();
        API.post("Colturer/get_by_id", { annee_id }).then(res => {
            setIsActive(res.data.Cloture);
            if(res.data.Cloture == 1){
                setDisabledAction(true)
            }
        });

    }, [annee_id]);

    const Offsymbol = () => {
        return (
            <div
                title="Activé"
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 12,
                    color: "#fff",
                    paddingRight: 2,
                }}
            >
                {"A"}
            </div>
        );
    };

    const OnSymbol = () => {
        return (
            <div
                title="Désactivé"
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 12,
                    color: "#fff",
                    paddingRight: 2,
                }}
            >
                {"D"}
            </div>
        );
    };

    const active = async () => {
        const newState = !isActive;
        setIsActive(newState);
        try {
            API.post("Colturer/add", {
                active:newState,
                annee_id,
                user_id
            }).then(res=>{
                if(res.data.status=== 200){
                    setDisabledAction(true)
                    toast.success(" 🔓✔️Cette année est clôturée avec succès.", {
                        containerId: "A",
                    })
                }
            });
        } catch (error) {
            toast.success(" 🔒❌ Une erreur a été survenu !!", {
                containerId: "A",
            })
        }
    };

    return (
        <React.Fragment>
            <div>
                <Row>
                    <AvForm>
                        <Row>
                            <h4 style={{ color: 'red' }}>{"Cette action est irréversible et ne peut être effectuée qu'une seule fois."}</h4>
                        </Row>
                        <Row>
                            <Col lg="3">
                                <div className="mb-3">
                                    <Label for="basicpill-firstname-input1">
                                        Clôturer cette année : {annee}
                                    </Label>
                                </div>
                            </Col>
                            <Col lg="3">
                                <div className="mb-3">
                                    <Switch
                                        uncheckedIcon={<Offsymbol />}
                                        checkedIcon={<OnSymbol />}
                                        className="me-1 mb-sm-8 mb-2"
                                        onColor="#34c38f"
                                        onChange={active}
                                        checked={isActive}
                                        disabled={disabledAction}
                                    />
                                </div>
                            </Col>
                        </Row>

                    </AvForm>
                </Row>
            </div>
            <ToastContainer
                transition={Slide}
                enableMultiContainer
                containerId={"A"}
                position={toast.POSITION.TOP_RIGHT}
                autoClose={2500}
            />
        </React.Fragment>
    );
};

export default withTranslation()(CloturerBaseDonnee);

CloturerBaseDonnee.propTypes = {
    history: PropTypes.object,
    back: PropTypes.object,
    setSection: PropTypes.object,
    t: PropTypes.any,
};
