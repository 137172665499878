import React, {useEffect, useState} from "react"
import MetaTags from "react-meta-tags"
import {Container, Card, CardBody, Nav, NavItem, NavLink} from "reactstrap"
import classnames from "classnames"
import {withRouter} from "react-router"
import DataTableScheduleSalle from "./DataTableScheduleSalle"
import DataTableSalle from "../Salle/DataTableSalle";
import AddSalle from "../Salle/AddSalle";

const ScheduleSalle = props => {
    const [section, setSection] = useState(0)
    const [semestre, setSemestre] = useState([
        {id: 1, label: "Emploi salle Semestre 1", section: 0},
        {id: 2, label: "Emploi salle Semestre 2", section: 1},
    ])
    const [add, setAdd] = useState(0);
    const [cloture, setCloture] = useState(0);
    useEffect(async () => {
        var cloture_annee = JSON.parse(localStorage.getItem("cloture"));
        setCloture(cloture_annee)
        const userAuth = JSON.parse(localStorage.getItem('UserMenu'));
        const typeArticleSubMenu = userAuth.find(user =>
            user.sous_menu.some(subMenu => subMenu.sous_menu === "Emploi salle")
        );
        setAdd(typeArticleSubMenu.sous_menu[0].add)
    }, [])
    const back = () => {
        setSection(3)
    }
    const renderBlock = section => {
        for (let i = 0; i < semestre.length; i++) {
            if (section === i) {
                return <DataTableScheduleSalle semestre={semestre[i].id}/>
            }
        }
        if (section === 3) {
            return <DataTableSalle/>
        } else if (section === 4) {
            return <AddSalle setSection={setSection} back={back}/>
        }
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Emploi salle | Cresus </title>
                </MetaTags>
                <Container fluid>
                    <div>
                        <Nav tabs>
                            {semestre.map((element, index) => (
                                <NavItem key={index}>
                                    <NavLink
                                        style={{cursor: "pointer"}}
                                        className={classnames({
                                            active: section === element.section,
                                        })}
                                        onClick={() => {
                                            setSection(element.section)
                                        }}
                                    >
                                        <span className="d-none d-sm-block">{element.label}</span>
                                    </NavLink>
                                </NavItem>
                            ))}
                            <NavItem>
                                <NavLink
                                    style={{cursor: "pointer"}}
                                    className={classnames({
                                        active: section === 3,
                                    })}
                                    onClick={() => {
                                        setSection(3)
                                    }}
                                >
                                    Salle
                                </NavLink>
                            </NavItem>
                            {cloture != 1 ? (
                                add === 1 ? (
                                    <NavItem>
                                        <NavLink
                                            style={{cursor: "pointer"}}
                                            className={classnames({
                                                active: section === 4,
                                            })}
                                            onClick={() => {
                                                setSection(4)
                                            }}
                                        >
                                            Ajouter salle
                                        </NavLink>
                                    </NavItem>
                                ) : (null)
                            ) : (null)}
                        </Nav>
                        <Card>
                            <CardBody>{renderBlock(section)}</CardBody>
                        </Card>
                    </div>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default withRouter(ScheduleSalle)
