import React, {Fragment, useEffect, useState} from "react"
import {withTranslation} from "react-i18next"
import {Button, CardTitle, Col, Spinner} from "reactstrap"
import PropTypes from "prop-types"
import Select from "react-select"
import API from "../../api"
import {Link} from "react-router-dom"

const GroupeSemestre1 = props => {
    const [arrayCours, setArrayCours] = useState([])
    const [arrayTD, setArrayTD] = useState([])
    const [arrayTP, setArrayTP] = useState([])
    const [arrayAutre, setArrayAutre] = useState([])
    const [loading, setLoading] = useState(false)
    const [cloture, setCloture] = useState(0);


    useEffect(async () => {
        var cloture_annee = JSON.parse(localStorage.getItem("cloture"));
        setCloture(cloture_annee)
        getMatiere()
    }, [props.semestre])
    var userAuthScolarite = JSON.parse(
        localStorage.getItem("userAuthScolarite")
    )

    var annee_id = userAuthScolarite.user.annee_id
    const getMatiere = async () => {
        var url = window.location.href
        var array = url.split("=")
        var id = array[1]
        const res = await API.post("group/get_group_enseignant", {
            enseignant_id: id,
            semestre_id: props.semestre.value,
            annee_id: annee_id
        }).then(res => {
            setArrayCours(res.data.GroupEnseignantMatiere[0].cours)
            setArrayTD(res.data.GroupEnseignantMatiere[0].td)
            setArrayTP(res.data.GroupEnseignantMatiere[0].tp)
            setArrayAutre(res.data.GroupEnseignantMatiere[0].autre)
            setLoading(true)
        })
    }

    const addGroupe = async (event, index) => {
        let groupe_id = event
        setArrayCours(
            arrayCours.map((el, id) =>
                id === index ? Object.assign(el, {group_id: groupe_id}) : el
            )
        )
    }

    const addGroupeTD = async (event, index) => {
        let groupe_id = event
        setArrayTD(
            arrayTD.map((el, id) =>
                id === index ? Object.assign(el, {group_id: groupe_id}) : el
            )
        )
    }

    const addGroupeTP = async (event, index) => {
        let groupe_id = event
        setArrayTP(
            arrayTP.map((el, id) =>
                id === index ? Object.assign(el, {group_id: groupe_id}) : el
            )
        )
    }

    const addGroupeAutre = async (event, index) => {
        let groupe_id = event
        setArrayAutre(
            arrayAutre.map((el, id) =>
                id === index ? Object.assign(el, {group_id: groupe_id}) : el
            )
        )
    }

    const save = async () => {
        setLoading(false)
        const res = await API.post("group/add_group_enseignant", {
            array_cour: arrayCours,
            array_td: arrayTD,
            array_tp: arrayTP,
            array_autre: arrayAutre,
        }).then(res => {
            getMatiere()
        })
    }

    return (
        <React.Fragment>
            {loading ? (
                <Fragment>
                    {arrayCours.length == 0 &&
                    arrayTD.length == 0 &&
                    arrayTP.length == 0 &&
                    arrayAutre.length == 0 ? (
                        <div
                            style={{
                                textAlign: "center",
                                fontWeight: "bold",
                                paddingTop: "2%",
                            }}
                        >
                            {" "}
                            Aucune matière validé
                        </div>
                    ) : (
                        <Fragment>
                            {arrayCours.length != 0 ? (
                                <Fragment>
                                    {" "}
                                    <CardTitle className="h4 mt-4">Cours :</CardTitle>
                                    <table className="table table-bordered mt-4">
                                        <thead>
                                        <tr>
                                            <th style={{textAlign: "center"}} scope="col">
                                                Groupe
                                            </th>
                                            <th style={{textAlign: "center"}} scope="col">
                                                Matière
                                            </th>
                                        </tr>
                                        </thead>
                                        {arrayCours.map((el, index) => (
                                            <tbody key={index}>
                                            <tr>
                                                <td className="col-md-6">
                                                    <Select
                                                        options={el.group}
                                                        isSearchable={true}
                                                        value={el.group_id}
                                                        onChange={e => addGroupe(e, index)}
                                                    />
                                                </td>
                                                <td className="col-md-6">
                                                    <Select isSearchable={true} value={el.matiere}/>
                                                </td>
                                            </tr>
                                            </tbody>
                                        ))}
                                    </table>
                                </Fragment>
                            ) : null}
                            {arrayTD.length != 0 ? (
                                <Fragment>
                                    {" "}
                                    <CardTitle className="h4 mt-4">TD :</CardTitle>
                                    <table className="table table-bordered mt-4">
                                        <thead>
                                        <tr>
                                            <th style={{textAlign: "center"}} scope="col">
                                                Groupe
                                            </th>
                                            <th style={{textAlign: "center"}} scope="col">
                                                Matière
                                            </th>
                                        </tr>
                                        </thead>
                                        {arrayTD.map((el, index) => (
                                            <tbody key={index}>
                                            <tr>
                                                <td className="col-md-6">
                                                    <Select
                                                        options={el.group}
                                                        isSearchable={true}
                                                        value={el.group_id}
                                                        onChange={e => addGroupeTD(e, index)}
                                                    />
                                                </td>
                                                <td className="col-md-6">
                                                    <Select isSearchable={true} value={el.matiere}/>
                                                </td>
                                            </tr>
                                            </tbody>
                                        ))}
                                    </table>
                                </Fragment>
                            ) : null}
                            {arrayTP.length != 0 ? (
                                <Fragment>
                                    {" "}
                                    <CardTitle className="h4 mt-4">TP :</CardTitle>
                                    <table className="table table-bordered mt-4">
                                        <thead>
                                        <tr>
                                            <th style={{textAlign: "center"}} scope="col">
                                                Groupe
                                            </th>
                                            <th style={{textAlign: "center"}} scope="col">
                                                Matière
                                            </th>
                                        </tr>
                                        </thead>
                                        {arrayTP.map((el, index) => (
                                            <tbody key={index}>
                                            <tr>
                                                <td className="col-md-6">
                                                    <Select
                                                        options={el.group}
                                                        isSearchable={true}
                                                        value={el.group_id}
                                                        onChange={e => addGroupeTP(e, index)}
                                                    />
                                                </td>
                                                <td className="col-md-6">
                                                    <Select isSearchable={true} value={el.matiere}/>
                                                </td>
                                            </tr>
                                            </tbody>
                                        ))}
                                    </table>
                                </Fragment>
                            ) : null}
                            {arrayAutre.length != 0 ? (
                                <Fragment>
                                    {" "}
                                    <CardTitle className="h4 mt-4">Autre :</CardTitle>
                                    <table className="table table-bordered mt-4">
                                        <thead>
                                        <tr>
                                            <th style={{textAlign: "center"}} scope="col">
                                                Groupe
                                            </th>
                                            <th style={{textAlign: "center"}} scope="col">
                                                Matière
                                            </th>
                                        </tr>
                                        </thead>
                                        {arrayAutre.map((el, index) => (
                                            <tbody key={index}>
                                            <tr>
                                                <td className="col-md-6">
                                                    <Select
                                                        options={el.group}
                                                        isSearchable={true}
                                                        value={el.group_id}
                                                        onChange={e => addGroupeAutre(e, index)}
                                                    />
                                                </td>
                                                <td className="col-md-6">
                                                    <Select isSearchable={true} value={el.matiere}/>
                                                </td>
                                            </tr>
                                            </tbody>
                                        ))}
                                    </table>
                                </Fragment>
                            ) : null}
                            <div style={{display: "flex", justifyContent: "center"}}>
                                {" "}
                                <Col lg="6">
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                        }}
                                        className="text-center mt-4"
                                    >
                                        <Link to={"/Enseignant"}>
                                            <Button
                                                type="button"
                                                color="warning"
                                                className="btn btn-warning  mb-2 me-2"
                                            >
                                                Annuler
                                            </Button>
                                        </Link>
                                    </div>
                                </Col>
                                {cloture != 1 ? (
                                    <Col lg="6">

                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "flex-start",
                                            }}
                                            className="text-center mt-4"
                                        >
                                            <button
                                                type="button"
                                                className="btn btn-primary "
                                                onClick={save}
                                            >
                                                CONFIRMER
                                            </button>
                                        </div>
                                    </Col>
                                ) : (
                                    <Col lg="6">

                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "flex-start",
                                            }}
                                            className="text-center mt-4"
                                        >
                                            <button
                                                type="button"
                                                className="btn btn-primary "
                                                disabled={true}
                                            >
                                                CONFIRMER
                                            </button>
                                        </div>
                                    </Col>
                                )}
                            </div>
                        </Fragment>
                    )}
                </Fragment>
            ) : (
                <div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <Spinner type="grow" className="ms-6" color="primary"/>
                    </div>
                    <h4 style={{textAlign: "center", marginTop: "2%"}} className="ms-6">
                        {" "}
                        En cours ...
                    </h4>
                </div>
            )}
        </React.Fragment>
    )
}

export default withTranslation()(GroupeSemestre1)
GroupeSemestre1.propTypes = {
    semestre: PropTypes.object,
    history: PropTypes.object,
}
