import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import API from "../../api"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import ToolkitProvider, {Search} from "react-bootstrap-table2-toolkit"
import {
    Row,
    Col,
    Spinner,
    Modal,
    ModalHeader,
    ModalBody,
    Button,
} from "reactstrap"
import {withRouter} from "react-router"
import {Link} from "react-router-dom"

const DataTableSpecialiteEnseignant = props => {
    const [loading, setLoading] = useState(false)
    const [modal, setModal] = useState(false)
    const [id, setId] = useState("")
    // Data Table
    const [orders, setOrders] = useState([])
    const [edit, setEdit] = useState(0);
    const [cloture, setCloture] = useState(0);
    const pageOptions = {
        loading: true,
        hidePageListOnlyOnePage: true,
        totalSize: orders.length,
        page: 1,
        nextPageText: "Suivant",
        prePageText: "Précédent",
        alwaysShowAllBtns: true,
        sizePerPageList: [
            {
                text: "5",
                value: 5,
            },
            {
                text: "10",
                value: 10,
            },
            {
                text: "20",
                value: 20,
            },
            {
                text: "30",
                value: 30,
            },
            {
                text: "All",
                value: orders.length,
            },
        ],
    }

    const NoDataIndication = () => (
        <h6 style={{textAlign: "center"}}>No record to display</h6>
    )
    const Columns = () => [
        {
            dataField: "name",
            text: "Spécialité enseignant",
            sort: true,
            style: {textAlign: "center"},
            headerStyle: (colum, colIndex) => {
                return {textAlign: "center"}
            },
        },
        {
            dataField: "Modification",
            isDummyField: true,
            text: "Modification",
            style: {textAlign: "center"},
            headerStyle: (colum, colIndex) => {
                return {textAlign: "center"}
            },
            sort: true,
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, row) => (
                cloture != 1 ? (
                    edit === 1 ? (
                        <div style={{display: "flex", justifyContent: "center"}}>
                            <Link to={"/EditSpécialitésEnseignant?id=" + row.id}>
                                <i
                                    style={{color: "cornflowerblue", cursor: "pointer"}}
                                    className="fas fa-edit"
                                ></i>
                            </Link>
                        </div>
                    ) : (
                        <div style={{display: "flex", justifyContent: "center"}}>
                            <i
                                style={{color: "#a7a3a3", cursor: "pointer"}}
                                className="fas fa-edit"
                            ></i>
                        </div>
                    )
                ) : (
                    <div style={{display: "flex", justifyContent: "center"}}>
                        <i
                            style={{color: "#a7a3a3", cursor: "pointer"}}
                            className="fas fa-edit"
                            title="Année clôturée"
                        ></i>
                    </div>
                )
            ),
        },
    ]

    const {SearchBar} = Search

    useEffect(async () => {
        var cloture_annee = JSON.parse(localStorage.getItem("cloture"));
        setCloture(cloture_annee)
        const userAuth = JSON.parse(localStorage.getItem('UserMenu'));
        const typeArticleSubMenu = userAuth.find(user =>
            user.sous_menu.some(subMenu => subMenu.sous_menu === "Spécialités enseignant")
        );
        setEdit(typeArticleSubMenu.sous_menu[1].edit)
        const res = await API.get("matiere/origine/list").then(res => {
            setOrders(res.data.MatiereOrigine)
        })
        setLoading(true)
    }, [])

    const toggle = id => {
        setModal(!modal)
        setId(id)
    }

    useEffect(() => {
        setModal(false)
    }, [orders])

    return (
        <React.Fragment>
            <div>
                {loading ? (
                    <Row>
                        <Col xs="12">
                            <ToolkitProvider
                                keyField="id"
                                data={orders}
                                columns={Columns()}
                                search
                                bootstrap4
                            >
                                {toolkitProps => (
                                    <div>
                                        <Col sm="4">
                                            <div className="search-box me-2 mb-2 d-inline-block">
                                                <div className="position-relative">
                                                    <SearchBar {...toolkitProps.searchProps} />
                                                    <i className="bx bx-search-alt search-icon"/>
                                                </div>
                                            </div>
                                        </Col>
                                        <BootstrapTable
                                            wrapperClasses="table-responsive"
                                            noDataIndication={() => <NoDataIndication/>}
                                            striped={false}
                                            bordered={false}
                                            classes={"table align-middle table-nowrap"}
                                            headerWrapperClasses={"table-light"}
                                            hover
                                            pagination={paginationFactory(pageOptions)}
                                            {...toolkitProps.baseProps}
                                        />
                                    </div>
                                )}
                            </ToolkitProvider>
                        </Col>
                    </Row>
                ) : (
                    <div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            {" "}
                            <Spinner type="grow" className="ms-6" color="primary"/>
                        </div>
                        <h4
                            style={{textAlign: "center", marginTop: "2%"}}
                            className="ms-6"
                        >
                            {" "}
                            En cours ...{" "}
                        </h4>
                    </div>
                )}
            </div>
        </React.Fragment>
    )
}

export default withRouter(DataTableSpecialiteEnseignant)
DataTableSpecialiteEnseignant.propTypes = {
    orders: PropTypes.array,
    history: PropTypes.object,
}
