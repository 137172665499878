import React, {useEffect, useState} from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import {Container, Card, CardBody, Nav, NavItem, NavLink} from "reactstrap"
import classnames from "classnames"
import {withRouter} from "react-router"
//i18n
import {withTranslation} from "react-i18next";
import AddSpecialite from "./AddSpecialite"
import DataTableSpecialite from "./DataTableSpecialite"

const Specialite = props => {
    const [section, setSection] = useState(1)
    const [add, setAdd] = useState(0);
    const [cloture, setCloture] = useState(0);
    useEffect(async () => {
        var cloture_annee = JSON.parse(localStorage.getItem("cloture"));
        setCloture(cloture_annee)
        const userAuth = JSON.parse(localStorage.getItem('UserMenu'));
        const typeArticleSubMenu = userAuth.find(user =>
            user.sous_menu.some(subMenu => subMenu.sous_menu === "Spécialité")
        );
        setAdd(typeArticleSubMenu.sous_menu[2].add)
    }, [])
    const back = () => {
        setSection(1)
    }

    const renderBlock = section => {
        if (section === 1) {
            return <DataTableSpecialite/>
        } else if (section === 2) {
            return <AddSpecialite setSection={setSection} back={back}/>
        }
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Spécialité | Cresus </title>
                </MetaTags>
                <Container fluid>
                    <div>
                        <Nav tabs>
                            <NavItem>
                                <NavLink
                                    style={{cursor: "pointer"}}
                                    className={classnames({
                                        active: section === 1,
                                    })}
                                    onClick={() => {
                                        setSection(1)
                                    }}
                                >
                                    {props.t("Spécialité")}
                                </NavLink>
                            </NavItem>
                            {cloture != 1 ? (
                                add === 1 ? (
                                    <NavItem>
                                        <NavLink
                                            style={{cursor: "pointer"}}
                                            className={classnames({
                                                active: section === 2,
                                            })}
                                            onClick={() => {
                                                setSection(2)
                                            }}
                                        >
                                            {props.t("Ajouter Spécialité")}
                                        </NavLink>
                                    </NavItem>
                                ) : (null)
                            ) : (null)}
                        </Nav>
                        <Card>
                            <CardBody>{renderBlock(section)}</CardBody>
                        </Card>
                    </div>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default withRouter(withTranslation()(Specialite))
Specialite.propTypes = {
    t: PropTypes.any,
}
