import React, {Fragment, useEffect, useState} from "react"
import MetaTags from "react-meta-tags"
import {Container, Card, CardBody, Nav, NavItem, NavLink} from "reactstrap"
import classnames from "classnames"
import {withRouter} from "react-router"
import DataTableGroupe from "./DataTableGroupe"
import AddGroupe from "./AddGroupe"
import AddGroupeTD from "./AddGroupeTD"
import AddGroupeTP from "./AddGroupeTP"

const Groupe = props => {
    const [section, setSection] = useState(1)
    const [add, setAdd] = useState(0);
    const [cloture, setCloture] = useState(0);
    useEffect(async () => {
        var cloture_annee = JSON.parse(localStorage.getItem("cloture"));
        setCloture(cloture_annee)
        const userAuth = JSON.parse(localStorage.getItem('UserMenu'));
        const typeArticleSubMenu = userAuth.find(user =>
            user.sous_menu.some(subMenu => subMenu.sous_menu === "Groupe")
        );
        setAdd(typeArticleSubMenu.sous_menu[3].add)
    }, [])
    const back = () => {
        setSection(1)
    }

    const renderBlock = section => {
        if (section === 1) {
            return <DataTableGroupe/>
        } else if (section === 2) {
            return <AddGroupe setSection={setSection} back={back}/>
        } else if (section === 3) {
            return <AddGroupeTD setSection={setSection} back={back}/>
        } else if (section === 4) {
            return <AddGroupeTP setSection={setSection} back={back}/>
        }
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Groupe | Cresus </title>
                </MetaTags>
                <Container fluid>
                    <div>
                        <Nav tabs>
                            <NavItem>
                                <NavLink
                                    style={{cursor: "pointer"}}
                                    className={classnames({
                                        active: section === 1,
                                    })}
                                    onClick={() => {
                                        setSection(1)
                                    }}
                                >
                                    Groupe
                                </NavLink>
                            </NavItem>
                            {cloture != 1 ? (
                                add === 1 ? (
                                    <Fragment>
                                        <NavItem>
                                            <NavLink
                                                style={{cursor: "pointer"}}
                                                className={classnames({
                                                    active: section === 2,
                                                })}
                                                onClick={() => {
                                                    setSection(2)
                                                }}
                                            >
                                                Ajouter groupes de cours
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                style={{cursor: "pointer"}}
                                                className={classnames({
                                                    active: section === 3,
                                                })}
                                                onClick={() => {
                                                    setSection(3)
                                                }}
                                            >
                                                Ajouter groupes de TD
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                style={{cursor: "pointer"}}
                                                className={classnames({
                                                    active: section === 4,
                                                })}
                                                onClick={() => {
                                                    setSection(4)
                                                }}
                                            >
                                                Ajouter groupes de TP
                                            </NavLink>
                                        </NavItem>
                                    </Fragment>
                                ) : (null)
                            ) : (null)}

                        </Nav>
                        <Card>
                            <CardBody>{renderBlock(section)}</CardBody>
                        </Card>
                    </div>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default withRouter(Groupe)
