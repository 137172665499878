import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import API from "../../api"
import {
  Col,
  Input,
  Row,
  Label,
  Form,
  Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Modal,
  ModalHeader,
  ModalBody,
  CardTitle,
} from "reactstrap"
// Toastify
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"
import Select from "react-select"

const AddGroupe = props => {
  const [semestre, setSemestre] = useState([
    { value: 1, label: "Semestre 1" },
    { value: 2, label: "Semestre 2" },
  ])
  const [selectSemestre, setSelectSemestre] = useState("")
  const [niveau, setNiveau] = useState([])
  const [selectNiveau, setSelectNiveau] = useState("")
  const [specialite, setSpecialite] = useState([])
  const [selectSpecialite, setSelectSpecialite] = useState("")
  const [nbrCours, setNbrCours] = useState("")
  const [arrayGroupe, setArrayGroupe] = useState([
    {
      identifiantGroupe: "",
      NomGroupe: "",
      capacite: "",
      nombreEtudiant: "",
    },
  ])
  const [modal, setModal] = useState(false)
  const [indexLigne, setIndexLigne] = useState("")
  const [nbrTD, setNbrTD] = useState("")
  const [nbrTP, setNbrTP] = useState("")

  useEffect(async () => {
    const res = await API.get("niveau/select").then(res => {
      setNiveau(res.data.Niveau)
    })
  }, [])

  const getSpecialite = async event => {
    setSelectNiveau(event)
    const res = await API.post("group/get_list_spec", {
      niveau_id: event.value,
      semestre_id: selectSemestre,
    }).then(res => {
      setSpecialite(res.data.Groups)
    })
  }

  const addLigne = () => {
    let element = {
      identifiantGroupe: "",
      NomGroupe: "",
      capacite: "",
      nombreEtudiant: "",
    }
    setArrayGroupe([...arrayGroupe, element])
  }

  const toggle = indexOfLigne => {
    setModal(!modal)
    setIndexLigne(indexOfLigne)
  }

  const toggleDelete = async () => {
    arrayGroupe.splice(indexLigne, 1)
    setModal(false)
  }

  const nbrArrayGroupe = event => {
    var nbr = event
    setNbrCours(event)
    var array = arrayGroupe
    if (event != "") {
      for (let i = 1; i < nbr; i++) {
        let element = {
          identifiantGroupe: "",
          NomGroupe: "",
          capacite: "",
          nombreEtudiant: "",
        }
        array.push(element)
      }
      setArrayGroupe([...array])

    } else {
      setArrayGroupe([
        {
          identifiantGroupe: "",
          NomGroupe: "",
          capacite: "",
          nombreEtudiant: "",
        },
      ])
    }
  }

  const addIdentifiantGroupe = (event, index) => {
    let identifiantGroupe = event.target.value
    setArrayGroupe(
      arrayGroupe.map((el, id) =>
        id === index
          ? Object.assign(el, { identifiantGroupe: identifiantGroupe })
          : el
      )
    )
  }

  const addNomGroupe = (event, index) => {
    let NomGroupe = event.target.value
    setArrayGroupe(
      arrayGroupe.map((el, id) =>
        id === index ? Object.assign(el, { NomGroupe: NomGroupe }) : el
      )
    )
  }

  const addCapacite = (event, index) => {
    let capacite = event.target.value
    setArrayGroupe(
      arrayGroupe.map((el, id) =>
        id === index ? Object.assign(el, { capacite: capacite }) : el
      )
    )
  }

  const addNombreEtudiant = (event, index) => {
    let nombreEtudiant = event.target.value
    setArrayGroupe(
      arrayGroupe.map((el, id) =>
        id === index
          ? Object.assign(el, { nombreEtudiant: nombreEtudiant })
          : el
      )
    )
  }

  const save = async () => {
    var etat = true
    if (selectSemestre == "") {
      var etat = false
      toast.error("⛔ Semestre obligatoire", {
        containerId: "A",
      })
    } else if (selectNiveau == "") {
      var etat = false
      toast.error("⛔ Niveau obligatoire", {
        containerId: "A",
      })
    } else if (selectSpecialite == "") {
      var etat = false
      toast.error("⛔ Spécialité obligatoire", {
        containerId: "A",
      })
    }
    if (etat) {
      var userAuthScolarite = JSON.parse(localStorage.getItem("userAuthScolarite"))
      var annee_id = userAuthScolarite.user.annee_id
      const res = await API.post("group/add", {
        annee_id:annee_id,
        semestre_id: selectSemestre,
        niveau_id: selectNiveau.value,
        specialite_id: selectSpecialite.value,
        nbrCours: nbrCours,
        nbrTD: nbrTD,
        nbrTP: nbrTP,
        groupsCours: arrayGroupe,
      }).then(res => {
        props.setSection(1)
      })
    }
  }

  return (
    <React.Fragment>
      <Row>
        <Form>
          <Row>
            <Col md={6}>
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">Semestre:</Label>
                <Select
                  options={semestre}
                  isSearchable={true}
                  isMulti={true}
                  onChange={e => setSelectSemestre(e)}
                />
              </div>
            </Col>
            <Col lg="6">
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">Niveau</Label>
                <Select
                  options={niveau}
                  isSearchable={true}
                  onChange={e => getSpecialite(e)}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">Spécialité</Label>
                <Select
                  options={specialite}
                  isSearchable={true}
                  onChange={e => setSelectSpecialite(e)}
                />
              </div>
            </Col>
            {/*<Col lg="6">*/}
            {/*  <div className="mb-3">*/}
            {/*    <Label for="basicpill-firstname-input1">Effectif :</Label>*/}
            {/*    <Input*/}
            {/*      className="form-control"*/}
            {/*      placeholder="Effectif"*/}
            {/*      type="number"*/}
            {/*      onChange={e => setEffectif(e.target.value)}*/}
            {/*    />*/}
            {/*  </div>*/}
            {/*</Col>*/}
            <Col lg="6">
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">
                  Nombre de groupe cours :
                </Label>
                <Input
                    className="form-control"
                    placeholder="Nombre de groupe cours"
                    type="number"
                    onChange={e => nbrArrayGroupe(e.target.value)}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">
                  Nombre de groupe TD :
                </Label>
                <Input
                  className="form-control"
                  placeholder="Nombre de groupe TD"
                  type="number"
                  onChange={e => setNbrTD(e.target.value)}
                />
              </div>
            </Col>
            <Col lg="6">
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">
                  Nombre de groupe TP :
                </Label>
                <Input
                    className="form-control"
                    placeholder="Nombre de groupe TP"
                    type="number"
                    onChange={e => setNbrTP(e.target.value)}
                />
              </div>
            </Col>
          </Row>
        </Form>
        <CardTitle className="h4 mt-4">Groupe de cours</CardTitle>
        <table className="table table-bordered mt-4">
          <thead>
            <tr>
              <th style={{ textAlign: "center" }} scope="col">
                Identifiant groupe
              </th>
              <th style={{ textAlign: "center" }} scope="col">
                Nom groupe
              </th>

              <th style={{ textAlign: "center" }} scope="col">
                Capacité
              </th>
              <th style={{ textAlign: "center" }} scope="col">
                Nombre etudiant
              </th>
              <th style={{ textAlign: "center" }} scope="col">
                {/* <i
                  style={{
                    color: "#a0ceb8",
                    cursor: "pointer",
                  }}
                  className="fas fa-plus"
                  onClick={addLigne}
                /> */}
              </th>
            </tr>
          </thead>
          {arrayGroupe.map((el, index) => (
            <tbody key={index}>
              <tr>
                <td className="col-md-2">
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Identifiant Groupe"
                    onChange={e => addIdentifiantGroupe(e, index)}
                    value={el.identifiantGroupe}
                  />
                </td>
                <td className="col-md-2">
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Nom Groupe"
                    onChange={e => addNomGroupe(e, index)}
                    value={el.NomGroupe}
                  />
                </td>

                <td className="col-md-2">
                  <Input
                    type="number"
                    className="form-control"
                    placeholder="Capacité"
                    onChange={e => addCapacite(e, index)}
                    value={el.capacite}
                  />
                </td>
                <td className="col-md-2">
                  <Input
                    type="number"
                    className="form-control"
                    placeholder="Nombre Etudiant"
                    onChange={e => addNombreEtudiant(e, index)}
                    value={el.nombreEtudiant}
                  />
                </td>
                <td className="col-md-2" style={{ textAlign: "center" }}>
                  <i
                    style={{ color: "#cc0000", cursor: "pointer" }}
                    className="mdi mdi-delete font-size-18"
                    id="deletetooltip"
                    onClick={() => toggle(index)}
                  />
                </td>
              </tr>
            </tbody>
          ))}
        </table>

        <div style={{ display: "flex", justifyContent: "center" }}>
          <Col lg="6">
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
              className="text-center mt-4"
            >
              <Button
                type="button"
                color="warning"
                className="btn btn-warning  mb-2 me-2"
                onClick={props.back}
              >
                Annuler
              </Button>
            </div>
          </Col>
          <Col lg="6">
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
              }}
              className="text-center mt-4"
            >
              <button type="button" className="btn btn-primary " onClick={save}>
                CONFIRMER
              </button>
            </div>
          </Col>
        </div>
      </Row>
      <ToastContainer
        transition={Slide}
        enableMultiContainer
        containerId={"A"}
        position={toast.POSITION.TOP_RIGHT}
        autoClose={2500}
      />
      <Modal isOpen={modal} toggle={toggle} centered={true}>
        <ModalHeader toggle={toggle} tag="h4">
          {" Suppression d'une ligne"}{" "}
        </ModalHeader>
        <ModalBody>
          <div
            style={{
              fontFamily: "Montserrat, sans-serif",
              FontSize: "14px",
              FontWeight: "700",
              LineHeight: "18.375px",
            }}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              <p>Voulez-vous supprimer cette ligne ?</p>
            </div>
            <div
              className="hvr-push"
              style={{
                display: "flex",
                justifyContent: "space-around",
                marginBottom: "15px",
              }}
            >
              <div>
                <Button
                  onClick={toggleDelete}
                  color="info"
                  className="btn-rounded "
                >
                  <i
                    style={{ color: "white" }}
                    className="far fa-thumbs-up"
                  ></i>
                  Oui
                </Button>
              </div>
              <div>
                <Button
                  onClick={toggle}
                  color="danger"
                  className="btn-rounded "
                >
                  <i
                    style={{ color: "white" }}
                    className="far fa-thumbs-down"
                  ></i>
                  Non
                </Button>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default AddGroupe
AddGroupe.propTypes = {
  history: PropTypes.object,
  back: PropTypes.object,
  setSection: PropTypes.object,
}
