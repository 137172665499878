import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import API from "../../api"
import {
  Col,
  Input,
  Row,
  Label,
  Form,
  Button,
  Container,
  Card,
  CardBody,
  CardTitle,
  Spinner,
} from "reactstrap"
// Toastify
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"
//i18n
import { withTranslation } from "react-i18next"
import Select from "react-select"

const EditMatiere = props => {
  const [matiere, setMatiere] = useState("")
  const [coeff, setCoeff] = useState("")
  const [credit, setCredit] = useState("")
  const [nbrCours, setNbrCours] = useState("")
  const [nbrTD, setNbrTD] = useState("")
  const [nbrTP, setNbrTP] = useState("")
  const [modalite, setModalite] = useState([
    { value: 1, label: "Régime mixte" },
    { value: 2, label: "Contrôle continu" },
  ])
  const [selectModalite, setSelectModalite] = useState("")
  const [autre, setAutre] = useState("")
  const [departement, setDepartement] = useState([])
  const [selectDepartement, setSelectDepartement] = useState("")
  const [loading, setLoading] = useState(false)
  const [semestre, setSemestre] = useState([
    { value: 1, label: "Semestre 1" },
    { value: 2, label: "Semestre 2" },
  ])
  const [optionAutre, setOptionAutre] = useState([
    { value: 1, label: "Cours Non Intégrée" },
    { value: 2, label: "Cours + TD" },
    { value: 3, label: "Cours + TP" },
  ])
  const [selectSemestre, setSelectSemestre] = useState("")
  const [selectOptionAutre, setSelectOptionAutre] = useState("");
  const [pourcentageCours, setPourcentageCours] = useState("");
  const [pourcentageTd, setPourcentageTd] = useState("");
  const [pourcentageTp, setPourcentageTp] = useState("");
  const [etat, setEtat] = useState("none");
  const [etatCours, setEtatCours] = useState("none");
  const [etatTd, setEtatTd] = useState("none");
  const [etatTp, setEtatTp] = useState("none");
  const [niveau, setNiveau] = useState([]);
  const [selectNiveau, setSelectNiveau] = useState("");
  useEffect(async () => {
    // Département
    const resD = await API.get("departement/select").then(resD => {
      setDepartement(resD.data.Departement)
    })
    var url = window.location.href
    var array = url.split("=")
    var id = array[1]
    const res = await API.post("matiere/get_by_id", { id }).then(res => {
      setMatiere(res.data.Matiere.name)
      setCoeff(res.data.Matiere.coef)
      setCredit(res.data.Matiere.credit)
      setNbrCours(res.data.Matiere.nbhCours)
      setNbrTD(res.data.Matiere.nbhTD)
      setNbrTP(res.data.Matiere.nbhTP)
      setAutre(res.data.Matiere.autre)
      setSelectModalite(res.data.Matiere.modalite)
      setSelectDepartement(res.data.Matiere.departement_id)
      setSelectNiveau(res.data.Matiere.niveau_id)
      setSelectSemestre(res.data.Matiere.semestre_id)
      setSelectOptionAutre(res.data.Matiere.type_ci)
      setPourcentageCours(res.data.Matiere.pourcentage_cour)
      setPourcentageTd(res.data.Matiere.pourcentage_td)
      setPourcentageTp(res.data.Matiere.pourcentage_tp)
      switch (res.data.Matiere.type_ci.value){
        case 1 :
          setEtatCours("none")
          setEtatTd("none")
          setEtatTp("none")
          break;
        case 2:
          setEtatCours("")
          setEtatTd("")
          setEtatTp("none")
          break;
        case 3 :
          setEtatCours("")
          setEtatTd("none")
          setEtatTp("")
          break ;
      }
      setLoading(true)
    })
  }, [])

  const edit = async () => {
    var url = window.location.href
    var array = url.split("=")
    var id = array[1]
    if (matiere != "" || selectSemestre!="") {
      if(selectOptionAutre.value == 1){
        var type_ci=0
      }else if(selectOptionAutre.value == 2){
        var type_ci=1
      }else{
        var type_ci=2
      }
      // alert(nbrTD ? nbrTD :0)
      const res = await API.post("matiere/update", {
        id: id,
        name: matiere,
        coef: coeff ? coeff :0,
        credit: credit ? credit :0,
        nbhCours: nbrCours ? nbrCours :0,
        nbhTD: nbrTD ? nbrTD :0,
        nbhTP:  nbrTP ? nbrTP : 0,
        autre: autre ? autre : 0,
        type_ci: type_ci,
        modalite_evaluation: selectModalite.label,
        departement_id: selectDepartement.value,
        semestre_id:selectSemestre.value,
        pourcentage_cour: pourcentageCours,
        pourcentage_td: pourcentageTd,
        pourcentage_tp: pourcentageTp
      }).then(res => {
        props.history.push("/Matiere")
      })
    } else {
      toast.error("⛔ Nom matière obligatoire", {
        containerId: "A",
      })
    }
  }
  const getPourcentage = async event => {
    setSelectOptionAutre(event)
    switch (event.value){
      case 1 :
        setEtatCours("none")
        setEtatTd("none")
        setEtatTp("none")
        setPourcentageCours("")
        setPourcentageTd("")
        setPourcentageTp("")
        break;
      case 2:
        setEtatCours("")
        setEtatTd("")
        setEtatTp("none")
        setPourcentageCours("")
        setPourcentageTd("")
        setPourcentageTp("")
        break;
      case 3 :
        setEtatCours("")
        setEtatTd("none")
        setEtatTp("")
        setPourcentageCours("")
        setPourcentageTd("")
        setPourcentageTp("")
        break ;
    }

  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Card>
            <CardBody>
              {loading ? (
                <Row>
                  <Form>
                    <Row>
                      <Col lg="3">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Departement :
                          </Label>
                          <Select
                              options={departement}
                              isSearchable={true}
                              value={selectDepartement}
                              onChange={e => setSelectDepartement(e)}
                          />
                        </div>
                      </Col>
                      <Col lg="3">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Niveau :
                          </Label>
                          <Select
                              options={niveau}
                              isSearchable={true}
                              value={selectNiveau}
                              onChange={e => setSelectNiveau(e)}
                          />
                        </div>
                      </Col>
                      <Col lg="3">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">Semestre</Label>
                          <Select
                              options={semestre}
                              isSearchable={true}
                              value={selectSemestre}
                              onChange={e => setSelectSemestre(e)}
                          />
                        </div>
                      </Col>
                      <Col lg="3">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Modalité :
                          </Label>
                          <Select
                              options={modalite}
                              isSearchable={true}
                              value={selectModalite}
                              onChange={e => setSelectModalite(e)}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>

                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Matière :
                          </Label>
                          <Input
                              lg="3"
                              type="text"
                              className="form-control"
                              placeholder="Matiere"
                              value={matiere}
                              onChange={e => setMatiere(e.target.value)}
                          />
                        </div>
                      </Col>


                      <Col lg="3">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Coeff :
                          </Label>
                          <Input
                            lg="3"
                            type="number"
                            className="form-control"
                            placeholder="Coeff"
                            value={coeff}
                            onChange={e => setCoeff(e.target.value)}
                          />
                        </div>
                      </Col>
                      <Col lg="3">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Crédit :
                          </Label>
                          <Input
                              lg="3"
                              type="number"
                              className="form-control"
                              placeholder="Crédit"
                              value={credit}
                              onChange={e => setCredit(e.target.value)}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>

                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Nombre heure cours :
                          </Label>
                          <Input
                            lg="3"
                            type="number"
                            className="form-control"
                            placeholder="Nbr heure cours"
                            value={nbrCours}
                            onChange={e => setNbrCours(e.target.value)}
                          />
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Nombre heure TD :
                          </Label>
                          <Input
                              lg="3"
                              type="number"
                              className="form-control"
                              placeholder="Nbr heure TD"
                              value={nbrTD}
                              onChange={e => setNbrTD(e.target.value)}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>

                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Nombre heure TP :
                          </Label>
                          <Input
                            lg="3"
                            type="text"
                            className="form-control"
                            placeholder="Nbr heure TP"
                            value={nbrTP}
                            onChange={e => setNbrTP(e.target.value)}
                          />
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Nombre heure autre :
                          </Label>
                          <Input
                              lg="3"
                              type="text"
                              className="form-control"
                              placeholder="Nbr heure autre"
                              value={autre}
                              onChange={e => setAutre(e.target.value)}
                          />
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Option Cours Intégrée :
                          </Label>
                          <Select
                              options={optionAutre}
                              isSearchable={true}
                              value={selectOptionAutre}
                              onChange={e => getPourcentage(e)}
                              // onChange={e => setSelectOptionAutre(e)}
                          />
                        </div>
                      </Col>

                        <Col style={{display:etatCours}} lg="3">
                          <div className="mb-3">
                            <Label for="basicpill-firstname-input1">
                              Pourcentage de cours :
                            </Label>
                            <Input
                                lg="3"
                                type="text"
                                className="form-control"
                                placeholder="Pourcentage de cours"
                                value={pourcentageCours}
                                onChange={e => setPourcentageCours(e.target.value)}
                            />
                          </div>
                        </Col>
                        <Col style={{display:etatTd}} lg="3">
                          <div className="mb-3">
                            <Label for="basicpill-firstname-input1">
                              Pourcentage de TD :
                            </Label>
                            <Input
                                lg="3"
                                type="text"
                                className="form-control"
                                placeholder="Pourcentage de TD"
                                value={pourcentageTd}
                                onChange={e => setPourcentageTd(e.target.value)}
                            />
                          </div>
                        </Col>
                        <Col style={{display:etatTp}} lg="3">
                          <div className="mb-3">
                            <Label for="basicpill-firstname-input1">
                              Pourcentage de TP :
                            </Label>
                            <Input
                                lg="3"
                                type="text"
                                className="form-control"
                                placeholder="Pourcentage de TP"
                                value={pourcentageTp}
                                onChange={e => setPourcentageTp(e.target.value)}
                            />
                          </div>
                        </Col>


                    </Row>
                  </Form>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    {" "}
                    <Col lg="6">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                        className="text-center mt-4"
                      >
                        <Button
                          type="button"
                          color="warning"
                          className="btn btn-warning  mb-2 me-2"
                          onClick={() => props.history.push("/Matiere")}
                        >
                          Annuler
                        </Button>
                      </div>
                    </Col>
                    <Col lg="6">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                        className="text-center mt-4"
                      >
                        <button
                          type="button"
                          className="btn btn-primary "
                          onClick={edit}
                        >
                          CONFIRMER
                        </button>
                      </div>
                    </Col>
                  </div>
                </Row>
              ) : (
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {" "}
                    <Spinner type="grow" className="ms-6" color="primary" />
                  </div>
                  <h4
                    style={{ textAlign: "center", marginTop: "2%" }}
                    className="ms-6"
                  >
                    {" "}
                    {"Chargement"} ...{" "}
                  </h4>
                </div>
              )}
            </CardBody>
          </Card>
          <ToastContainer
            transition={Slide}
            enableMultiContainer
            containerId={"A"}
            position={toast.POSITION.TOP_RIGHT}
            autoClose={2500}
          />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(EditMatiere)
EditMatiere.propTypes = {
  history: PropTypes.object,
  t: PropTypes.any,
}
