import React, { useEffect, useState } from "react"
import {Button, Col, Modal, ModalBody, Row} from "reactstrap"
// Toastify
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"
import APIS from "../../api"
import { withTranslation } from "react-i18next"
import {Link} from "react-router-dom";

const DeuxiemeAnneeSemestreUn = props => {
    var url = window.location.href
    var array = url.split("=")
    var enseignant_id = array[1]
  const [array3, setArray3] = useState([])
  const [array4, setArray4] = useState([])


  useEffect(async () => {
    const res3 = APIS.post("fiche/voeux_by_matiere_sou", {
      cycle: 1,
      niveau_semestre: 3,
      enseignant_id: enseignant_id,
    }).then(res3 => {
      setArray3(res3.data.voeux)
    })
    const res4 = APIS.post("fiche/voeux_by_matiere_sou", {
      cycle: 2,
      niveau_semestre: 3,
      enseignant_id: enseignant_id,
    }).then(res4 => {
      setArray4(res4.data.voeux)
    })
  }, [])



    return (
        <React.Fragment>
            <Row>
                <div
                    style={{
                        paddingTop: "10px",
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    <h2 style={{ color: "#f4912d" }}>Cycle :</h2>
                    <h2 style={{ paddingLeft: "5px", color: "#f4912d" }}>Licence</h2>
                </div>
                {array3.map((el, index) => (
                    <div key={index}>
                        <h3
                            style={{
                                paddingTop: "10px",
                                color: "#0074ba",
                                textAlign: "center",
                            }}
                        >
                            {el.specalite}
                        </h3>
                        {el.matiere.map((elem, indice) => (
                            <div key={indice}>
                                {elem.matiere != "" ? (
                                    <div>
                                        {" "}
                                        <div
                                            style={{
                                                paddingTop: "10px",
                                                display: "flex",
                                            }}
                                        >
                                            <h4 style={{ color: "#057fc7" }}>Matière :</h4>
                                            <h4 style={{ paddingLeft: "5px", color: "#057fc7" }}>
                                                {elem.matiere}
                                            </h4>
                                        </div>
                                        <div>
                                            <h5 style={{ textAlign: "center", color: "red" }}>
                                                Cours
                                            </h5>
                                            <table className="table table-bordered mt-1">
                                                <thead>
                                                <tr
                                                    style={{
                                                        textAlign: "center",
                                                        border: "2px solid",
                                                        backgroundColor: "#95b3d7",
                                                    }}
                                                >
                                                    <th
                                                        className="col-md-1"
                                                        style={{
                                                            textAlign: "center",
                                                            border: "2px solid",
                                                        }}
                                                    >
                                                        Ordre de préférence
                                                    </th>
                                                    <th
                                                        className="col-md-1"
                                                        style={{
                                                            textAlign: "center",
                                                            border: "2px solid",
                                                        }}
                                                    >
                                                        CIN
                                                    </th>
                                                    <th
                                                        className="col-md-2"
                                                        style={{
                                                            textAlign: "center",
                                                            border: "2px solid",
                                                        }}
                                                    >
                                                        Enseignant
                                                    </th>
                                                    <th
                                                        className="col-md-2"
                                                        style={{
                                                            textAlign: "center",
                                                            border: "2px solid",
                                                        }}
                                                    >
                                                        Grade
                                                    </th>
                                                    <th
                                                        className="col-md-2"
                                                        style={{
                                                            textAlign: "center",
                                                            border: "2px solid",
                                                        }}
                                                    >
                                                        Statut
                                                    </th>
                                                    <th
                                                        className="col-md-2"
                                                        style={{
                                                            textAlign: "center",
                                                            border: "2px solid",
                                                        }}
                                                    >
                                                        Nombre heure
                                                    </th>
                                                    <th
                                                        className="col-md-2"
                                                        style={{
                                                            textAlign: "center",
                                                            border: "2px solid",
                                                        }}
                                                    >
                                                        Nombre de groupes
                                                    </th>
                                                    <th
                                                        className="col-md-2"
                                                        style={{
                                                            textAlign: "center",
                                                            border: "2px solid",
                                                        }}
                                                    >
                                                        Action
                                                    </th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {elem.cours.length != 0
                                                    ? elem.cours.map((element, ind) => (
                                                        <tr key={ind}>
                                                            <td
                                                                className="col-md-1"
                                                                style={{
                                                                    textAlign: "center",
                                                                    border: "2px solid",
                                                                }}
                                                            >
                                                                {element.priorite}
                                                            </td>
                                                            <td
                                                                className="col-md-2"
                                                                style={{
                                                                    textAlign: "center",
                                                                    border: "2px solid",
                                                                }}
                                                            >
                                                                {element.cin}
                                                            </td>
                                                            <td
                                                                className="col-md-2"
                                                                style={{
                                                                    textAlign: "center",
                                                                    border: "2px solid",
                                                                }}
                                                            >
                                                                {element.enseignant}
                                                            </td>
                                                            <td
                                                                className="col-md-2"
                                                                style={{
                                                                    textAlign: "center",
                                                                    border: "2px solid",
                                                                }}
                                                            >
                                                                {element.grade}
                                                            </td>
                                                            <td
                                                                className="col-md-2"
                                                                style={{
                                                                    textAlign: "center",
                                                                    border: "2px solid",
                                                                }}
                                                            >
                                                                {element.statut}
                                                            </td>
                                                            <td
                                                                className="col-md-2"
                                                                style={{
                                                                    textAlign: "center",
                                                                    border: "2px solid",
                                                                }}
                                                            >
                                                                {element.nb_heure}
                                                            </td>
                                                            {element.etat == 0 ? (
                                                                <td
                                                                    className="col-md-1"
                                                                    style={{
                                                                        textAlign: "center",
                                                                        border: "2px solid",
                                                                        verticalAlign: "middle",
                                                                    }}
                                                                >
                                                                    <div
                                                                        style={{
                                                                            display: "flex",
                                                                            justifyContent: "center",
                                                                        }}
                                                                    >
                                                                        0

                                                                    </div>
                                                                </td>


                                                            ) : (
                                                                <td
                                                                    className="col-md-2"
                                                                    style={{
                                                                        textAlign: "center",
                                                                        border: "2px solid",
                                                                    }}
                                                                >
                                                                    {element.nb_group}
                                                                </td>
                                                            )}
                                                            {element.etat == 0 ? (

                                                                <td
                                                                    className="col-md-1"
                                                                    style={{
                                                                        textAlign: "center",
                                                                        border: "2px solid",
                                                                        verticalAlign: "middle",
                                                                    }}
                                                                >
                                                                    <div
                                                                        style={{
                                                                            display: "flex",
                                                                            justifyContent: "center",
                                                                        }}
                                                                    >
                                                                        <i
                                                                            style={{
                                                                                color: "#FF0000",
                                                                                cursor: "pointer",
                                                                            }}
                                                                            className="fas fa-spinner fa-pulse"
                                                                        ></i>

                                                                    </div>
                                                                </td>
                                                            ) : (
                                                                <td
                                                                    className="col-md-1"
                                                                    style={{
                                                                        textAlign: "center",
                                                                        border: "2px solid",
                                                                        verticalAlign: "middle",
                                                                    }}
                                                                >
                                                                    <div
                                                                        style={{
                                                                            display: "flex",
                                                                            justifyContent: "center",
                                                                        }}
                                                                    >
                                                                        <i
                                                                            style={{
                                                                                color: "#2ca67a",
                                                                                cursor: "pointer",
                                                                            }}
                                                                            className="fas fa-check"
                                                                        ></i>

                                                                    </div>
                                                                </td>
                                                            )}

                                                        </tr>
                                                    ))
                                                    : null}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div>
                                            <h5 style={{ textAlign: "center", color: "red" }}>TD</h5>
                                            <table className="table table-bordered mt-1">
                                                <thead>
                                                <tr
                                                    style={{
                                                        textAlign: "center",
                                                        border: "2px solid",
                                                        backgroundColor: "#95b3d7",
                                                    }}
                                                >
                                                    <th
                                                        className="col-md-1"
                                                        style={{
                                                            textAlign: "center",
                                                            border: "2px solid",
                                                        }}
                                                    >
                                                        Ordre de préférence
                                                    </th>
                                                    <th
                                                        className="col-md-1"
                                                        style={{
                                                            textAlign: "center",
                                                            border: "2px solid",
                                                        }}
                                                    >
                                                        CIN
                                                    </th>
                                                    <th
                                                        className="col-md-2"
                                                        style={{
                                                            textAlign: "center",
                                                            border: "2px solid",
                                                        }}
                                                    >
                                                        Enseignant
                                                    </th>
                                                    <th
                                                        className="col-md-2"
                                                        style={{
                                                            textAlign: "center",
                                                            border: "2px solid",
                                                        }}
                                                    >
                                                        Grade
                                                    </th>
                                                    <th
                                                        className="col-md-2"
                                                        style={{
                                                            textAlign: "center",
                                                            border: "2px solid",
                                                        }}
                                                    >
                                                        Statut
                                                    </th>
                                                    <th
                                                        className="col-md-2"
                                                        style={{
                                                            textAlign: "center",
                                                            border: "2px solid",
                                                        }}
                                                    >
                                                        Nombre heure
                                                    </th>
                                                    <th
                                                        className="col-md-2"
                                                        style={{
                                                            textAlign: "center",
                                                            border: "2px solid",
                                                        }}
                                                    >
                                                        Nombre de groupes
                                                    </th>
                                                    <th
                                                        className="col-md-2"
                                                        style={{
                                                            textAlign: "center",
                                                            border: "2px solid",
                                                        }}
                                                    >
                                                        Action
                                                    </th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {elem.TD.length != 0
                                                    ? elem.TD.map((element, ind) => (
                                                        <tr key={ind}>
                                                            <td
                                                                className="col-md-1"
                                                                style={{
                                                                    textAlign: "center",
                                                                    border: "2px solid",
                                                                }}
                                                            >
                                                                {element.priorite}
                                                            </td>
                                                            <td
                                                                className="col-md-2"
                                                                style={{
                                                                    textAlign: "center",
                                                                    border: "2px solid",
                                                                }}
                                                            >
                                                                {element.cin}
                                                            </td>
                                                            <td
                                                                className="col-md-2"
                                                                style={{
                                                                    textAlign: "center",
                                                                    border: "2px solid",
                                                                }}
                                                            >
                                                                {element.enseignant}
                                                            </td>
                                                            <td
                                                                className="col-md-2"
                                                                style={{
                                                                    textAlign: "center",
                                                                    border: "2px solid",
                                                                }}
                                                            >
                                                                {element.grade}
                                                            </td>
                                                            <td
                                                                className="col-md-2"
                                                                style={{
                                                                    textAlign: "center",
                                                                    border: "2px solid",
                                                                }}
                                                            >
                                                                {element.statut}
                                                            </td>

                                                            <td
                                                                className="col-md-2"
                                                                style={{
                                                                    textAlign: "center",
                                                                    border: "2px solid",
                                                                }}
                                                            >
                                                                {element.nb_heure}
                                                            </td>
                                                            {element.etat == 0 ? (
                                                                <td
                                                                    className="col-md-1"
                                                                    style={{
                                                                        textAlign: "center",
                                                                        border: "2px solid",
                                                                        verticalAlign: "middle",
                                                                    }}
                                                                >
                                                                    <div
                                                                        style={{
                                                                            display: "flex",
                                                                            justifyContent: "center",
                                                                        }}
                                                                    >
                                                                        0

                                                                    </div>
                                                                </td>


                                                            ) : (
                                                                <td
                                                                    className="col-md-2"
                                                                    style={{
                                                                        textAlign: "center",
                                                                        border: "2px solid",
                                                                    }}
                                                                >
                                                                    {element.nb_group}
                                                                </td>
                                                            )}
                                                            {element.etat == 0 ? (

                                                                <td
                                                                    className="col-md-1"
                                                                    style={{
                                                                        textAlign: "center",
                                                                        border: "2px solid",
                                                                        verticalAlign: "middle",
                                                                    }}
                                                                >
                                                                    <div
                                                                        style={{
                                                                            display: "flex",
                                                                            justifyContent: "center",
                                                                        }}
                                                                    >
                                                                        <i
                                                                            style={{
                                                                                color: "#FF0000",
                                                                                cursor: "pointer",
                                                                            }}
                                                                            className="fas fa-spinner fa-pulse"
                                                                        ></i>

                                                                    </div>
                                                                </td>
                                                            ) : (
                                                                <td
                                                                    className="col-md-1"
                                                                    style={{
                                                                        textAlign: "center",
                                                                        border: "2px solid",
                                                                        verticalAlign: "middle",
                                                                    }}
                                                                >
                                                                    <div
                                                                        style={{
                                                                            display: "flex",
                                                                            justifyContent: "center",
                                                                        }}
                                                                    >
                                                                        <i
                                                                            style={{
                                                                                color: "#2ca67a",
                                                                                cursor: "pointer",
                                                                            }}
                                                                            className="fas fa-check"
                                                                        ></i>

                                                                    </div>
                                                                </td>
                                                            )}
                                                        </tr>
                                                    ))
                                                    : null}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div>
                                            <h5 style={{ textAlign: "center", color: "red" }}>TP</h5>
                                            <table className="table table-bordered mt-1">
                                                <thead>
                                                <tr
                                                    style={{
                                                        textAlign: "center",
                                                        border: "2px solid",
                                                        backgroundColor: "#95b3d7",
                                                    }}
                                                >
                                                    <th
                                                        className="col-md-1"
                                                        style={{
                                                            textAlign: "center",
                                                            border: "2px solid",
                                                        }}
                                                    >
                                                        Ordre de préférence
                                                    </th>
                                                    <th
                                                        className="col-md-1"
                                                        style={{
                                                            textAlign: "center",
                                                            border: "2px solid",
                                                        }}
                                                    >
                                                        CIN
                                                    </th>
                                                    <th
                                                        className="col-md-2"
                                                        style={{
                                                            textAlign: "center",
                                                            border: "2px solid",
                                                        }}
                                                    >
                                                        Enseignant
                                                    </th>
                                                    <th
                                                        className="col-md-2"
                                                        style={{
                                                            textAlign: "center",
                                                            border: "2px solid",
                                                        }}
                                                    >
                                                        Grade
                                                    </th>
                                                    <th
                                                        className="col-md-2"
                                                        style={{
                                                            textAlign: "center",
                                                            border: "2px solid",
                                                        }}
                                                    >
                                                        Statut
                                                    </th>
                                                    <th
                                                        className="col-md-2"
                                                        style={{
                                                            textAlign: "center",
                                                            border: "2px solid",
                                                        }}
                                                    >
                                                        Nombre heure
                                                    </th>
                                                    <th
                                                        className="col-md-2"
                                                        style={{
                                                            textAlign: "center",
                                                            border: "2px solid",
                                                        }}
                                                    >
                                                        Nombre de groupes
                                                    </th>
                                                    <th
                                                        className="col-md-2"
                                                        style={{
                                                            textAlign: "center",
                                                            border: "2px solid",
                                                        }}
                                                    >
                                                        Action
                                                    </th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {elem.TP.length != 0
                                                    ? elem.TP.map((element, ind) => (
                                                        <tr key={ind}>
                                                            <td
                                                                className="col-md-1"
                                                                style={{
                                                                    textAlign: "center",
                                                                    border: "2px solid",
                                                                }}
                                                            >
                                                                {element.priorite}
                                                            </td>
                                                            <td
                                                                className="col-md-2"
                                                                style={{
                                                                    textAlign: "center",
                                                                    border: "2px solid",
                                                                }}
                                                            >
                                                                {element.cin}
                                                            </td>
                                                            <td
                                                                className="col-md-2"
                                                                style={{
                                                                    textAlign: "center",
                                                                    border: "2px solid",
                                                                }}
                                                            >
                                                                {element.enseignant}
                                                            </td>
                                                            <td
                                                                className="col-md-2"
                                                                style={{
                                                                    textAlign: "center",
                                                                    border: "2px solid",
                                                                }}
                                                            >
                                                                {element.grade}
                                                            </td>
                                                            <td
                                                                className="col-md-2"
                                                                style={{
                                                                    textAlign: "center",
                                                                    border: "2px solid",
                                                                }}
                                                            >
                                                                {element.statut}
                                                            </td>
                                                            <td
                                                                className="col-md-2"
                                                                style={{
                                                                    textAlign: "center",
                                                                    border: "2px solid",
                                                                }}
                                                            >
                                                                {element.nb_heure}
                                                            </td>
                                                            {element.etat == 0 ? (
                                                                <td
                                                                    className="col-md-1"
                                                                    style={{
                                                                        textAlign: "center",
                                                                        border: "2px solid",
                                                                        verticalAlign: "middle",
                                                                    }}
                                                                >
                                                                    <div
                                                                        style={{
                                                                            display: "flex",
                                                                            justifyContent: "center",
                                                                        }}
                                                                    >
                                                                        0

                                                                    </div>
                                                                </td>


                                                            ) : (
                                                                <td
                                                                    className="col-md-2"
                                                                    style={{
                                                                        textAlign: "center",
                                                                        border: "2px solid",
                                                                    }}
                                                                >
                                                                    {element.nb_group}
                                                                </td>
                                                            )}
                                                            {element.etat == 0 ? (

                                                                <td
                                                                    className="col-md-1"
                                                                    style={{
                                                                        textAlign: "center",
                                                                        border: "2px solid",
                                                                        verticalAlign: "middle",
                                                                    }}
                                                                >
                                                                    <div
                                                                        style={{
                                                                            display: "flex",
                                                                            justifyContent: "center",
                                                                        }}
                                                                    >
                                                                        <i
                                                                            style={{
                                                                                color: "#FF0000",
                                                                                cursor: "pointer",
                                                                            }}
                                                                            className="fas fa-spinner fa-pulse"
                                                                        ></i>

                                                                    </div>
                                                                </td>
                                                            ) : (
                                                                <td
                                                                    className="col-md-1"
                                                                    style={{
                                                                        textAlign: "center",
                                                                        border: "2px solid",
                                                                        verticalAlign: "middle",
                                                                    }}
                                                                >
                                                                    <div
                                                                        style={{
                                                                            display: "flex",
                                                                            justifyContent: "center",
                                                                        }}
                                                                    >
                                                                        <i
                                                                            style={{
                                                                                color: "#2ca67a",
                                                                                cursor: "pointer",
                                                                            }}
                                                                            className="fas fa-check"
                                                                        ></i>

                                                                    </div>
                                                                </td>
                                                            )}
                                                        </tr>
                                                    ))
                                                    : null}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div>
                                            <h5 style={{ textAlign: "center", color: "red" }}>CI</h5>
                                            <table className="table table-bordered mt-1">
                                                <thead>
                                                <tr
                                                    style={{
                                                        textAlign: "center",
                                                        border: "2px solid",
                                                        backgroundColor: "#95b3d7",
                                                    }}
                                                >
                                                    <th
                                                        className="col-md-1"
                                                        style={{
                                                            textAlign: "center",
                                                            border: "2px solid",
                                                        }}
                                                    >
                                                        Ordre de préférence
                                                    </th>
                                                    <th
                                                        className="col-md-1"
                                                        style={{
                                                            textAlign: "center",
                                                            border: "2px solid",
                                                        }}
                                                    >
                                                        CIN
                                                    </th>
                                                    <th
                                                        className="col-md-2"
                                                        style={{
                                                            textAlign: "center",
                                                            border: "2px solid",
                                                        }}
                                                    >
                                                        Enseignant
                                                    </th>
                                                    <th
                                                        className="col-md-2"
                                                        style={{
                                                            textAlign: "center",
                                                            border: "2px solid",
                                                        }}
                                                    >
                                                        Grade
                                                    </th>
                                                    <th
                                                        className="col-md-2"
                                                        style={{
                                                            textAlign: "center",
                                                            border: "2px solid",
                                                        }}
                                                    >
                                                        Statut
                                                    </th>
                                                    <th
                                                        className="col-md-2"
                                                        style={{
                                                            textAlign: "center",
                                                            border: "2px solid",
                                                        }}
                                                    >
                                                        Nombre heure
                                                    </th>
                                                    <th
                                                        className="col-md-2"
                                                        style={{
                                                            textAlign: "center",
                                                            border: "2px solid",
                                                        }}
                                                    >
                                                        Nombre de groupes
                                                    </th>
                                                    <th
                                                        className="col-md-2"
                                                        style={{
                                                            textAlign: "center",
                                                            border: "2px solid",
                                                        }}
                                                    >
                                                        Action
                                                    </th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {elem.Autre.length != 0
                                                    ? elem.Autre.map((element, ind) => (
                                                        <tr key={ind}>
                                                            <td
                                                                className="col-md-1"
                                                                style={{
                                                                    textAlign: "center",
                                                                    border: "2px solid",
                                                                }}
                                                            >
                                                                {element.priorite}
                                                            </td>
                                                            <td
                                                                className="col-md-2"
                                                                style={{
                                                                    textAlign: "center",
                                                                    border: "2px solid",
                                                                }}
                                                            >
                                                                {element.cin}
                                                            </td>
                                                            <td
                                                                className="col-md-2"
                                                                style={{
                                                                    textAlign: "center",
                                                                    border: "2px solid",
                                                                }}
                                                            >
                                                                {element.enseignant}
                                                            </td>
                                                            <td
                                                                className="col-md-2"
                                                                style={{
                                                                    textAlign: "center",
                                                                    border: "2px solid",
                                                                }}
                                                            >
                                                                {element.grade}
                                                            </td>
                                                            <td
                                                                className="col-md-2"
                                                                style={{
                                                                    textAlign: "center",
                                                                    border: "2px solid",
                                                                }}
                                                            >
                                                                {element.statut}
                                                            </td>
                                                            <td
                                                                className="col-md-2"
                                                                style={{
                                                                    textAlign: "center",
                                                                    border: "2px solid",
                                                                }}
                                                            >
                                                                {element.nb_heure}
                                                            </td>
                                                            {element.etat == 0 ? (
                                                                <td
                                                                    className="col-md-1"
                                                                    style={{
                                                                        textAlign: "center",
                                                                        border: "2px solid",
                                                                        verticalAlign: "middle",
                                                                    }}
                                                                >
                                                                    <div
                                                                        style={{
                                                                            display: "flex",
                                                                            justifyContent: "center",
                                                                        }}
                                                                    >
                                                                        0

                                                                    </div>
                                                                </td>


                                                            ) : (
                                                                <td
                                                                    className="col-md-2"
                                                                    style={{
                                                                        textAlign: "center",
                                                                        border: "2px solid",
                                                                    }}
                                                                >
                                                                    {element.nb_group}
                                                                </td>
                                                            )}
                                                            {element.etat == 0 ? (

                                                                <td
                                                                    className="col-md-1"
                                                                    style={{
                                                                        textAlign: "center",
                                                                        border: "2px solid",
                                                                        verticalAlign: "middle",
                                                                    }}
                                                                >
                                                                    <div
                                                                        style={{
                                                                            display: "flex",
                                                                            justifyContent: "center",
                                                                        }}
                                                                    >
                                                                        <i
                                                                            style={{
                                                                                color: "#FF0000",
                                                                                cursor: "pointer",
                                                                            }}
                                                                            className="fas fa-spinner fa-pulse"
                                                                        ></i>

                                                                    </div>
                                                                </td>
                                                            ) : (
                                                                <td
                                                                    className="col-md-1"
                                                                    style={{
                                                                        textAlign: "center",
                                                                        border: "2px solid",
                                                                        verticalAlign: "middle",
                                                                    }}
                                                                >
                                                                    <div
                                                                        style={{
                                                                            display: "flex",
                                                                            justifyContent: "center",
                                                                        }}
                                                                    >
                                                                        <i
                                                                            style={{
                                                                                color: "#2ca67a",
                                                                                cursor: "pointer",
                                                                            }}
                                                                            className="fas fa-check"
                                                                        ></i>

                                                                    </div>
                                                                </td>
                                                            )}
                                                        </tr>
                                                    ))
                                                    : null}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        ))}
                    </div>
                ))}
            </Row>
            <Row>
                <div
                    style={{
                        paddingTop: "10px",
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    <h2 style={{ color: "#f4912d" }}>Cycle :</h2>
                    <h2 style={{ paddingLeft: "5px", color: "#f4912d" }}>Mastère</h2>
                </div>
                {array4.map((el, index) => (
                    <div key={index}>
                        <h3
                            style={{
                                paddingTop: "10px",
                                color: "#0074ba",
                                textAlign: "center",
                            }}
                        >
                            {el.specalite}
                        </h3>
                        {el.matiere.map((elem, indice) => (
                            <div key={indice}>
                                {elem.matiere != "" ? (
                                    <div>
                                        {" "}
                                        <div
                                            style={{
                                                paddingTop: "10px",
                                                display: "flex",
                                            }}
                                        >
                                            <h4 style={{ color: "#057fc7" }}>Matière :</h4>
                                            <h4 style={{ paddingLeft: "5px", color: "#057fc7" }}>
                                                {elem.matiere}
                                            </h4>
                                        </div>
                                        <div>
                                            <h5 style={{ textAlign: "center", color: "red" }}>
                                                Cours
                                            </h5>
                                            <table className="table table-bordered mt-1">
                                                <thead>
                                                <tr
                                                    style={{
                                                        textAlign: "center",
                                                        border: "2px solid",
                                                        backgroundColor: "#95b3d7",
                                                    }}
                                                >
                                                    <th
                                                        className="col-md-1"
                                                        style={{
                                                            textAlign: "center",
                                                            border: "2px solid",
                                                        }}
                                                    >
                                                        Ordre de préférence
                                                    </th>
                                                    <th
                                                        className="col-md-1"
                                                        style={{
                                                            textAlign: "center",
                                                            border: "2px solid",
                                                        }}
                                                    >
                                                        CIN
                                                    </th>
                                                    <th
                                                        className="col-md-2"
                                                        style={{
                                                            textAlign: "center",
                                                            border: "2px solid",
                                                        }}
                                                    >
                                                        Enseignant
                                                    </th>
                                                    <th
                                                        className="col-md-2"
                                                        style={{
                                                            textAlign: "center",
                                                            border: "2px solid",
                                                        }}
                                                    >
                                                        Grade
                                                    </th>
                                                    <th
                                                        className="col-md-2"
                                                        style={{
                                                            textAlign: "center",
                                                            border: "2px solid",
                                                        }}
                                                    >
                                                        Statut
                                                    </th>
                                                    <th
                                                        className="col-md-2"
                                                        style={{
                                                            textAlign: "center",
                                                            border: "2px solid",
                                                        }}
                                                    >
                                                        Nombre heure
                                                    </th>
                                                    <th
                                                        className="col-md-2"
                                                        style={{
                                                            textAlign: "center",
                                                            border: "2px solid",
                                                        }}
                                                    >
                                                        Nombre de groupes
                                                    </th>
                                                    <th
                                                        className="col-md-2"
                                                        style={{
                                                            textAlign: "center",
                                                            border: "2px solid",
                                                        }}
                                                    >
                                                        Action
                                                    </th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {elem.cours.length != 0
                                                    ? elem.cours.map((element, ind) => (
                                                        <tr key={ind}>
                                                            <td
                                                                className="col-md-1"
                                                                style={{
                                                                    textAlign: "center",
                                                                    border: "2px solid",
                                                                }}
                                                            >
                                                                {element.priorite}
                                                            </td>
                                                            <td
                                                                className="col-md-2"
                                                                style={{
                                                                    textAlign: "center",
                                                                    border: "2px solid",
                                                                }}
                                                            >
                                                                {element.cin}
                                                            </td>
                                                            <td
                                                                className="col-md-2"
                                                                style={{
                                                                    textAlign: "center",
                                                                    border: "2px solid",
                                                                }}
                                                            >
                                                                {element.enseignant}
                                                            </td>
                                                            <td
                                                                className="col-md-2"
                                                                style={{
                                                                    textAlign: "center",
                                                                    border: "2px solid",
                                                                }}
                                                            >
                                                                {element.grade}
                                                            </td>
                                                            <td
                                                                className="col-md-2"
                                                                style={{
                                                                    textAlign: "center",
                                                                    border: "2px solid",
                                                                }}
                                                            >
                                                                {element.statut}
                                                            </td>
                                                            <td
                                                                className="col-md-2"
                                                                style={{
                                                                    textAlign: "center",
                                                                    border: "2px solid",
                                                                }}
                                                            >
                                                                {element.nb_heure}
                                                            </td>
                                                            {element.etat == 0 ? (
                                                                <td
                                                                    className="col-md-1"
                                                                    style={{
                                                                        textAlign: "center",
                                                                        border: "2px solid",
                                                                        verticalAlign: "middle",
                                                                    }}
                                                                >
                                                                    <div
                                                                        style={{
                                                                            display: "flex",
                                                                            justifyContent: "center",
                                                                        }}
                                                                    >
                                                                        0

                                                                    </div>
                                                                </td>


                                                            ) : (
                                                                <td
                                                                    className="col-md-2"
                                                                    style={{
                                                                        textAlign: "center",
                                                                        border: "2px solid",
                                                                    }}
                                                                >
                                                                    {element.nb_group}
                                                                </td>
                                                            )}
                                                            {element.etat == 0 ? (

                                                                <td
                                                                    className="col-md-1"
                                                                    style={{
                                                                        textAlign: "center",
                                                                        border: "2px solid",
                                                                        verticalAlign: "middle",
                                                                    }}
                                                                >
                                                                    <div
                                                                        style={{
                                                                            display: "flex",
                                                                            justifyContent: "center",
                                                                        }}
                                                                    >
                                                                        <i
                                                                            style={{
                                                                                color: "#FF0000",
                                                                                cursor: "pointer",
                                                                            }}
                                                                            className="fas fa-spinner fa-pulse"
                                                                        ></i>

                                                                    </div>
                                                                </td>
                                                            ) : (
                                                                <td
                                                                    className="col-md-1"
                                                                    style={{
                                                                        textAlign: "center",
                                                                        border: "2px solid",
                                                                        verticalAlign: "middle",
                                                                    }}
                                                                >
                                                                    <div
                                                                        style={{
                                                                            display: "flex",
                                                                            justifyContent: "center",
                                                                        }}
                                                                    >
                                                                        <i
                                                                            style={{
                                                                                color: "#2ca67a",
                                                                                cursor: "pointer",
                                                                            }}
                                                                            className="fas fa-check"
                                                                        ></i>

                                                                    </div>
                                                                </td>
                                                            )}
                                                        </tr>
                                                    ))
                                                    : null}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div>
                                            <h5 style={{ textAlign: "center", color: "red" }}>TD</h5>
                                            <table className="table table-bordered mt-1">
                                                <thead>
                                                <tr
                                                    style={{
                                                        textAlign: "center",
                                                        border: "2px solid",
                                                        backgroundColor: "#95b3d7",
                                                    }}
                                                >
                                                    <th
                                                        className="col-md-1"
                                                        style={{
                                                            textAlign: "center",
                                                            border: "2px solid",
                                                        }}
                                                    >
                                                        Ordre de préférence
                                                    </th>
                                                    <th
                                                        className="col-md-1"
                                                        style={{
                                                            textAlign: "center",
                                                            border: "2px solid",
                                                        }}
                                                    >
                                                        CIN
                                                    </th>
                                                    <th
                                                        className="col-md-2"
                                                        style={{
                                                            textAlign: "center",
                                                            border: "2px solid",
                                                        }}
                                                    >
                                                        Enseignant
                                                    </th>
                                                    <th
                                                        className="col-md-2"
                                                        style={{
                                                            textAlign: "center",
                                                            border: "2px solid",
                                                        }}
                                                    >
                                                        Grade
                                                    </th>
                                                    <th
                                                        className="col-md-2"
                                                        style={{
                                                            textAlign: "center",
                                                            border: "2px solid",
                                                        }}
                                                    >
                                                        Statut
                                                    </th>
                                                    <th
                                                        className="col-md-2"
                                                        style={{
                                                            textAlign: "center",
                                                            border: "2px solid",
                                                        }}
                                                    >
                                                        Nombre heure
                                                    </th>
                                                    <th
                                                        className="col-md-2"
                                                        style={{
                                                            textAlign: "center",
                                                            border: "2px solid",
                                                        }}
                                                    >
                                                        Nombre de groupes
                                                    </th>
                                                    <th
                                                        className="col-md-2"
                                                        style={{
                                                            textAlign: "center",
                                                            border: "2px solid",
                                                        }}
                                                    >
                                                        Action
                                                    </th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {elem.TD.length != 0
                                                    ? elem.TD.map((element, ind) => (
                                                        <tr key={ind}>
                                                            <td
                                                                className="col-md-1"
                                                                style={{
                                                                    textAlign: "center",
                                                                    border: "2px solid",
                                                                }}
                                                            >
                                                                {element.priorite}
                                                            </td>
                                                            <td
                                                                className="col-md-2"
                                                                style={{
                                                                    textAlign: "center",
                                                                    border: "2px solid",
                                                                }}
                                                            >
                                                                {element.cin}
                                                            </td>
                                                            <td
                                                                className="col-md-2"
                                                                style={{
                                                                    textAlign: "center",
                                                                    border: "2px solid",
                                                                }}
                                                            >
                                                                {element.enseignant}
                                                            </td>
                                                            <td
                                                                className="col-md-2"
                                                                style={{
                                                                    textAlign: "center",
                                                                    border: "2px solid",
                                                                }}
                                                            >
                                                                {element.grade}
                                                            </td>
                                                            <td
                                                                className="col-md-2"
                                                                style={{
                                                                    textAlign: "center",
                                                                    border: "2px solid",
                                                                }}
                                                            >
                                                                {element.statut}
                                                            </td>
                                                            <td
                                                                className="col-md-2"
                                                                style={{
                                                                    textAlign: "center",
                                                                    border: "2px solid",
                                                                }}
                                                            >
                                                                {element.nb_heure}
                                                            </td>
                                                            {element.etat == 0 ? (
                                                                <td
                                                                    className="col-md-1"
                                                                    style={{
                                                                        textAlign: "center",
                                                                        border: "2px solid",
                                                                        verticalAlign: "middle",
                                                                    }}
                                                                >
                                                                    <div
                                                                        style={{
                                                                            display: "flex",
                                                                            justifyContent: "center",
                                                                        }}
                                                                    >
                                                                        0

                                                                    </div>
                                                                </td>


                                                            ) : (
                                                                <td
                                                                    className="col-md-2"
                                                                    style={{
                                                                        textAlign: "center",
                                                                        border: "2px solid",
                                                                    }}
                                                                >
                                                                    {element.nb_group}
                                                                </td>
                                                            )}
                                                            {element.etat == 0 ? (

                                                                <td
                                                                    className="col-md-1"
                                                                    style={{
                                                                        textAlign: "center",
                                                                        border: "2px solid",
                                                                        verticalAlign: "middle",
                                                                    }}
                                                                >
                                                                    <div
                                                                        style={{
                                                                            display: "flex",
                                                                            justifyContent: "center",
                                                                        }}
                                                                    >
                                                                        <i
                                                                            style={{
                                                                                color: "#FF0000",
                                                                                cursor: "pointer",
                                                                            }}
                                                                            className="fas fa-spinner fa-pulse"
                                                                        ></i>

                                                                    </div>
                                                                </td>
                                                            ) : (
                                                                <td
                                                                    className="col-md-1"
                                                                    style={{
                                                                        textAlign: "center",
                                                                        border: "2px solid",
                                                                        verticalAlign: "middle",
                                                                    }}
                                                                >
                                                                    <div
                                                                        style={{
                                                                            display: "flex",
                                                                            justifyContent: "center",
                                                                        }}
                                                                    >
                                                                        <i
                                                                            style={{
                                                                                color: "#2ca67a",
                                                                                cursor: "pointer",
                                                                            }}
                                                                            className="fas fa-check"
                                                                        ></i>

                                                                    </div>
                                                                </td>
                                                            )}
                                                        </tr>
                                                    ))
                                                    : null}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div>
                                            <h5 style={{ textAlign: "center", color: "red" }}>TP</h5>
                                            <table className="table table-bordered mt-1">
                                                <thead>
                                                <tr
                                                    style={{
                                                        textAlign: "center",
                                                        border: "2px solid",
                                                        backgroundColor: "#95b3d7",
                                                    }}
                                                >
                                                    <th
                                                        className="col-md-1"
                                                        style={{
                                                            textAlign: "center",
                                                            border: "2px solid",
                                                        }}
                                                    >
                                                        Ordre de préférence
                                                    </th>
                                                    <th
                                                        className="col-md-1"
                                                        style={{
                                                            textAlign: "center",
                                                            border: "2px solid",
                                                        }}
                                                    >
                                                        CIN
                                                    </th>
                                                    <th
                                                        className="col-md-2"
                                                        style={{
                                                            textAlign: "center",
                                                            border: "2px solid",
                                                        }}
                                                    >
                                                        Enseignant
                                                    </th>
                                                    <th
                                                        className="col-md-2"
                                                        style={{
                                                            textAlign: "center",
                                                            border: "2px solid",
                                                        }}
                                                    >
                                                        Grade
                                                    </th>
                                                    <th
                                                        className="col-md-2"
                                                        style={{
                                                            textAlign: "center",
                                                            border: "2px solid",
                                                        }}
                                                    >
                                                        Statut
                                                    </th>
                                                    <th
                                                        className="col-md-2"
                                                        style={{
                                                            textAlign: "center",
                                                            border: "2px solid",
                                                        }}
                                                    >
                                                        Nombre heure
                                                    </th>
                                                    <th
                                                        className="col-md-2"
                                                        style={{
                                                            textAlign: "center",
                                                            border: "2px solid",
                                                        }}
                                                    >
                                                        Nombre de groupes
                                                    </th>
                                                    <th
                                                        className="col-md-2"
                                                        style={{
                                                            textAlign: "center",
                                                            border: "2px solid",
                                                        }}
                                                    >
                                                        Action
                                                    </th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {elem.TP.length != 0
                                                    ? elem.TP.map((element, ind) => (
                                                        <tr key={ind}>
                                                            <td
                                                                className="col-md-1"
                                                                style={{
                                                                    textAlign: "center",
                                                                    border: "2px solid",
                                                                }}
                                                            >
                                                                {element.priorite}
                                                            </td>
                                                            <td
                                                                className="col-md-2"
                                                                style={{
                                                                    textAlign: "center",
                                                                    border: "2px solid",
                                                                }}
                                                            >
                                                                {element.cin}
                                                            </td>
                                                            <td
                                                                className="col-md-2"
                                                                style={{
                                                                    textAlign: "center",
                                                                    border: "2px solid",
                                                                }}
                                                            >
                                                                {element.enseignant}
                                                            </td>
                                                            <td
                                                                className="col-md-2"
                                                                style={{
                                                                    textAlign: "center",
                                                                    border: "2px solid",
                                                                }}
                                                            >
                                                                {element.grade}
                                                            </td>
                                                            <td
                                                                className="col-md-2"
                                                                style={{
                                                                    textAlign: "center",
                                                                    border: "2px solid",
                                                                }}
                                                            >
                                                                {element.statut}
                                                            </td>
                                                            <td
                                                                className="col-md-2"
                                                                style={{
                                                                    textAlign: "center",
                                                                    border: "2px solid",
                                                                }}
                                                            >
                                                                {element.nb_heure}
                                                            </td>
                                                            {element.etat == 0 ? (
                                                                <td
                                                                    className="col-md-1"
                                                                    style={{
                                                                        textAlign: "center",
                                                                        border: "2px solid",
                                                                        verticalAlign: "middle",
                                                                    }}
                                                                >
                                                                    <div
                                                                        style={{
                                                                            display: "flex",
                                                                            justifyContent: "center",
                                                                        }}
                                                                    >
                                                                        0

                                                                    </div>
                                                                </td>


                                                            ) : (
                                                                <td
                                                                    className="col-md-2"
                                                                    style={{
                                                                        textAlign: "center",
                                                                        border: "2px solid",
                                                                    }}
                                                                >
                                                                    {element.nb_group}
                                                                </td>
                                                            )}
                                                            {element.etat == 0 ? (

                                                                <td
                                                                    className="col-md-1"
                                                                    style={{
                                                                        textAlign: "center",
                                                                        border: "2px solid",
                                                                        verticalAlign: "middle",
                                                                    }}
                                                                >
                                                                    <div
                                                                        style={{
                                                                            display: "flex",
                                                                            justifyContent: "center",
                                                                        }}
                                                                    >
                                                                        <i
                                                                            style={{
                                                                                color: "#FF0000",
                                                                                cursor: "pointer",
                                                                            }}
                                                                            className="fas fa-spinner fa-pulse"
                                                                        ></i>

                                                                    </div>
                                                                </td>
                                                            ) : (
                                                                <td
                                                                    className="col-md-1"
                                                                    style={{
                                                                        textAlign: "center",
                                                                        border: "2px solid",
                                                                        verticalAlign: "middle",
                                                                    }}
                                                                >
                                                                    <div
                                                                        style={{
                                                                            display: "flex",
                                                                            justifyContent: "center",
                                                                        }}
                                                                    >
                                                                        <i
                                                                            style={{
                                                                                color: "#2ca67a",
                                                                                cursor: "pointer",
                                                                            }}
                                                                            className="fas fa-check"
                                                                        ></i>

                                                                    </div>
                                                                </td>
                                                            )}
                                                        </tr>
                                                    ))
                                                    : null}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div>
                                            <h5 style={{ textAlign: "center", color: "red" }}>CI</h5>
                                            <table className="table table-bordered mt-1">
                                                <thead>
                                                <tr
                                                    style={{
                                                        textAlign: "center",
                                                        border: "2px solid",
                                                        backgroundColor: "#95b3d7",
                                                    }}
                                                >
                                                    <th
                                                        className="col-md-1"
                                                        style={{
                                                            textAlign: "center",
                                                            border: "2px solid",
                                                        }}
                                                    >
                                                        Ordre de préférence
                                                    </th>
                                                    <th
                                                        className="col-md-1"
                                                        style={{
                                                            textAlign: "center",
                                                            border: "2px solid",
                                                        }}
                                                    >
                                                        CIN
                                                    </th>
                                                    <th
                                                        className="col-md-2"
                                                        style={{
                                                            textAlign: "center",
                                                            border: "2px solid",
                                                        }}
                                                    >
                                                        Enseignant
                                                    </th>
                                                    <th
                                                        className="col-md-2"
                                                        style={{
                                                            textAlign: "center",
                                                            border: "2px solid",
                                                        }}
                                                    >
                                                        Grade
                                                    </th>
                                                    <th
                                                        className="col-md-2"
                                                        style={{
                                                            textAlign: "center",
                                                            border: "2px solid",
                                                        }}
                                                    >
                                                        Statut
                                                    </th>
                                                    <th
                                                        className="col-md-2"
                                                        style={{
                                                            textAlign: "center",
                                                            border: "2px solid",
                                                        }}
                                                    >
                                                        Nombre heure
                                                    </th>
                                                    <th
                                                        className="col-md-2"
                                                        style={{
                                                            textAlign: "center",
                                                            border: "2px solid",
                                                        }}
                                                    >
                                                        Nombre de groupes
                                                    </th>
                                                    <th
                                                        className="col-md-2"
                                                        style={{
                                                            textAlign: "center",
                                                            border: "2px solid",
                                                        }}
                                                    >
                                                        Action
                                                    </th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {elem.Autre.length != 0
                                                    ? elem.Autre.map((element, ind) => (
                                                        <tr key={ind}>
                                                            <td
                                                                className="col-md-1"
                                                                style={{
                                                                    textAlign: "center",
                                                                    border: "2px solid",
                                                                }}
                                                            >
                                                                {element.priorite}
                                                            </td>
                                                            <td
                                                                className="col-md-2"
                                                                style={{
                                                                    textAlign: "center",
                                                                    border: "2px solid",
                                                                }}
                                                            >
                                                                {element.cin}
                                                            </td>
                                                            <td
                                                                className="col-md-2"
                                                                style={{
                                                                    textAlign: "center",
                                                                    border: "2px solid",
                                                                }}
                                                            >
                                                                {element.enseignant}
                                                            </td>
                                                            <td
                                                                className="col-md-2"
                                                                style={{
                                                                    textAlign: "center",
                                                                    border: "2px solid",
                                                                }}
                                                            >
                                                                {element.grade}
                                                            </td>
                                                            <td
                                                                className="col-md-2"
                                                                style={{
                                                                    textAlign: "center",
                                                                    border: "2px solid",
                                                                }}
                                                            >
                                                                {element.statut}
                                                            </td>
                                                            <td
                                                                className="col-md-2"
                                                                style={{
                                                                    textAlign: "center",
                                                                    border: "2px solid",
                                                                }}
                                                            >
                                                                {element.nb_heure}
                                                            </td>
                                                            {element.etat == 0 ? (
                                                                <td
                                                                    className="col-md-1"
                                                                    style={{
                                                                        textAlign: "center",
                                                                        border: "2px solid",
                                                                        verticalAlign: "middle",
                                                                    }}
                                                                >
                                                                    <div
                                                                        style={{
                                                                            display: "flex",
                                                                            justifyContent: "center",
                                                                        }}
                                                                    >
                                                                        0

                                                                    </div>
                                                                </td>


                                                            ) : (
                                                                <td
                                                                    className="col-md-2"
                                                                    style={{
                                                                        textAlign: "center",
                                                                        border: "2px solid",
                                                                    }}
                                                                >
                                                                    {element.nb_group}
                                                                </td>
                                                            )}
                                                            {element.etat == 0 ? (

                                                                <td
                                                                    className="col-md-1"
                                                                    style={{
                                                                        textAlign: "center",
                                                                        border: "2px solid",
                                                                        verticalAlign: "middle",
                                                                    }}
                                                                >
                                                                    <div
                                                                        style={{
                                                                            display: "flex",
                                                                            justifyContent: "center",
                                                                        }}
                                                                    >
                                                                        <i
                                                                            style={{
                                                                                color: "#FF0000",
                                                                                cursor: "pointer",
                                                                            }}
                                                                            className="fas fa-spinner fa-pulse"
                                                                        ></i>

                                                                    </div>
                                                                </td>
                                                            ) : (
                                                                <td
                                                                    className="col-md-1"
                                                                    style={{
                                                                        textAlign: "center",
                                                                        border: "2px solid",
                                                                        verticalAlign: "middle",
                                                                    }}
                                                                >
                                                                    <div
                                                                        style={{
                                                                            display: "flex",
                                                                            justifyContent: "center",
                                                                        }}
                                                                    >
                                                                        <i
                                                                            style={{
                                                                                color: "#2ca67a",
                                                                                cursor: "pointer",
                                                                            }}
                                                                            className="fas fa-check"
                                                                        ></i>

                                                                    </div>
                                                                </td>
                                                            )}
                                                        </tr>
                                                    ))
                                                    : null}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        ))}
                    </div>
                ))}
            </Row>
            <div style={{ display: "center", justifyContent: "center" }}>
                {" "}
                <Col lg="6">
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "flex-end",
                        }}
                        className="text-center mt-4"
                    >
                        <Link   type="button"
                                color="warning"
                                className="btn btn-warning  mb-2 me-2"
                                to={"/Enseignant"}>
                            Anuuler
                        </Link>
                    </div>
                </Col>

            </div>
        </React.Fragment>
    )
}

export default withTranslation()(DeuxiemeAnneeSemestreUn)
