import React, {Fragment, useEffect, useState} from "react"
import MetaTags from "react-meta-tags"
import {Container, Card, CardBody, Nav, NavItem, NavLink} from "reactstrap"
import classnames from "classnames"
import {withRouter} from "react-router"
import AddMatiere from "./AddMatiere"
import DataTableMatiere from "./DataTableMatiere"
import AffectationModuleMatiere from "./AffectationModuleMatiere"
import DataTableModuleMatiere from "./DataTableModuleMatiere"

const Matiere = props => {
    const [section, setSection] = useState(1)
    const [add, setAdd] = useState(0);
    const [cloture, setCloture] = useState(0);
    useEffect(async () => {
        var cloture_annee = JSON.parse(localStorage.getItem("cloture"));
        setCloture(cloture_annee)
        const userAuth = JSON.parse(localStorage.getItem('UserMenu'));
        const typeArticleSubMenu = userAuth.find(user =>
            user.sous_menu.some(subMenu => subMenu.sous_menu === "Matière")
        );
        setAdd(typeArticleSubMenu.sous_menu[2].add)
    }, [])

    const back = () => {
        setSection(1)
    }

    const renderBlock = section => {
        if (section === 1) {
            return <DataTableMatiere/>
        } else if (section === 2) {
            return <AddMatiere setSection={setSection} back={back}/>
        } else if (section === 3) {
            return <DataTableModuleMatiere/>
        } else if (section === 4) {
            return <AffectationModuleMatiere setSection={setSection} back={back}/>
        }
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Matière | Cresus </title>
                </MetaTags>
                <Container fluid>
                    <div>
                        <Nav tabs>
                            <NavItem>
                                <NavLink
                                    style={{cursor: "pointer"}}
                                    className={classnames({
                                        active: section === 1,
                                    })}
                                    onClick={() => {
                                        setSection(1)
                                    }}
                                >
                                    Matière
                                </NavLink>
                            </NavItem>
                            {add === 1 ? (
                                <Fragment>
                                    {cloture != 1 ? (
                                        <NavItem>
                                            <NavLink
                                                style={{cursor: "pointer"}}
                                                className={classnames({
                                                    active: section === 2,
                                                })}
                                                onClick={() => {
                                                    setSection(2)
                                                }}
                                            >
                                                Ajouter matière
                                            </NavLink>
                                        </NavItem>
                                    ) : (null)}
                                    <NavItem>
                                        <NavLink
                                            style={{cursor: "pointer"}}
                                            className={classnames({
                                                active: section === 3,
                                            })}
                                            onClick={() => {
                                                setSection(3)
                                            }}
                                        >
                                            Matière/Module
                                        </NavLink>
                                    </NavItem>
                                    {cloture != 1 ? (
                                        <NavItem>
                                            <NavLink
                                                style={{cursor: "pointer"}}
                                                className={classnames({
                                                    active: section === 4,
                                                })}
                                                onClick={() => {
                                                    setSection(4)
                                                }}
                                            >
                                                Affectation matière/Module
                                            </NavLink>
                                        </NavItem>
                                    ) : (null)}
                                </Fragment>
                            ) : (null)}
                        </Nav>
                        <Card>
                            <CardBody>{renderBlock(section)}</CardBody>
                        </Card>
                    </div>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default withRouter(Matiere)
