import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import API from "../../api"
import {
  Col,
  Input,
  Row,
  Label,
  Form,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap"
// Toastify
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"
import Select from "react-select"
import AddNiveau from "pages/Niveau/AddNiveau"

const AddSpecialite = props => {
  const [cycle, setCycle] = useState([])
  const [selectCycle, setSelectCycle] = useState("")
  const [diplome, setDiplome] = useState([])
  const [selectDiplome, setSelectDiplome] = useState("")
  const [arraySpec, setArraySpec] = useState([{ name: "", niveaux: [] }])
  const [modal, setModal] = useState(false)
  const [indexLigne, setIndexLigne] = useState("")
  const [niveaux, setNiveaux] = useState([])
  const [specialite, setSpecialite] = useState("Tronc commun")
  const [checked, setChecked] = useState(false)
  const [departement, setDepartement] = useState([]);
  const [selectDepartement, setSelectDepartement] = useState("");

  const handleChange = event => {
    setChecked(!checked)
    if (event.target.checked) {
      setArraySpec([
        { name: specialite, niveaux: [] },
        { name: "", niveaux: [] },
      ])
    } else {
      setArraySpec([{ name: "", niveaux: [] }])
    }
  }

  useEffect(async () => {
    const res = await API.get("cycle/select").then(res => {
      setCycle(res.data.Cycle)
    })

    const resDep = await API.get("departement/select").then(resDep => {
      setDepartement(resDep.data.Departement)
    })
  }, [])

  const getDiplome = async event => {
    var cycle_id = event.value
    setSelectCycle(event)
    const res = await API.post("diplome/get_by_cycle", { id: cycle_id }).then(
      res => {
        setDiplome(res.data.Diplomes_By_Cycle)
      }
    )
    const resN = await API.post("niveau/get_by_cycle", { id: cycle_id }).then(
      resN => {
        setNiveaux(resN.data.Niveaux_by_cycle)
      }
    )
  }

  const addLigne = () => {
    let element = {
      name: "",
      niveaux: [],
    }
    setArraySpec([...arraySpec, element])
  }

  const toggle = indexOfLigne => {
    setModal(!modal)
    setIndexLigne(indexOfLigne)
  }

  const toggleDelete = async () => {
    arraySpec.splice(indexLigne, 1)
    setModal(false)
  }

  const addNiv = (event, index) => {
    setArraySpec(
      arraySpec.map((el, id) =>
        id === index ? Object.assign(el, { niveaux: event }) : el
      )
    )
  }
  const addSpec = (event, index) => {
    let name = event.target.value
    setArraySpec(
      arraySpec.map((el, id) =>
        id === index ? Object.assign(el, { name: name }) : el
      )
    )
  }

  const save = async () => {
    var etat = true
    for (let i = 0; i < arraySpec.length; i++) {
      var spec = arraySpec[i].name
      var niveau = arraySpec[i].niveaux
      if (selectCycle == "") {
        var etat = false
        toast.error("⛔ Cycle obligatoire", {
          containerId: "A",
        })
      } else if (selectDiplome == "") {
        var etat = false
        toast.error("⛔ Diplôme obligatoire", {
          containerId: "A",
        })
      }else if (selectDepartement == "") {
        var etat = false
        toast.error("⛔ Département obligatoire", {
          containerId: "A",
        })
      } else if (spec == "" || niveau.length == 0) {
        var etat = false
        toast.error("⛔ Remplir les champs vides", {
          containerId: "A",
        })
      }
    }
    if (arraySpec.length == 0) {
      var etat = false
      toast.error("⛔ Saisir au moins une spécialité", {
        containerId: "A",
      })
    }
    if (etat) {
      const res = await API.post("specialite/add", {
        diplome_id: selectDiplome.value,
        departement_id:selectDepartement.value,
        specialites: arraySpec,
      }).then(res => {
        props.setSection(1)
      })
    }
  }

  return (
    <React.Fragment>
      <Row>
        <Form>
          <Row>
            <Col lg="6">
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">Département</Label>
                <Select
                    options={departement}
                    isSearchable={true}
                    value={selectDepartement}
                    onChange={e => setSelectDepartement(e)}
                />
              </div>
            </Col>
            <Col lg="6">
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">Cycle</Label>
                <Select
                  options={cycle}
                  isSearchable={true}
                  onChange={e => getDiplome(e)}
                />
              </div>
            </Col>

          </Row>
          <Row>
            <Col lg="6">
              <div className="form-check form-check-inline">
                <Label className="form-check-label mb-3">
                  Spécialités tronc commun : &nbsp;
                </Label>
                <input
                  type="checkbox"
                  checked={checked}
                  onChange={e => handleChange(e)}
                />
              </div>
            </Col>
            <Col lg="6">
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">Diplôme</Label>
                <Select
                    options={diplome}
                    isSearchable={true}
                    value={selectDiplome}
                    onChange={e => setSelectDiplome(e)}
                />
              </div>
            </Col>
          </Row>
        </Form>
        <table className="table table-bordered mt-4">
          <thead>
            <tr>
              <th style={{ textAlign: "center" }} scope="col">
                Spécialité
              </th>
              <th style={{ textAlign: "center" }} scope="col">
                Niveau
              </th>
              <th style={{ textAlign: "center" }} scope="col">
                <i
                  style={{
                    color: "#a0ceb8",
                    cursor: "pointer",
                  }}
                  className="fas fa-plus"
                  onClick={addLigne}
                />
              </th>
            </tr>
          </thead>
          {arraySpec.map((el, index) => (
            <tbody key={index}>
              <tr>
                <td className="col-md-6">
                  <Input
                    style={{ textAlign: "center" }}
                    type="text"
                    className="form-control"
                    placeholder="Spécialité..."
                    value={el.name}
                    onChange={e => addSpec(e, index)}
                  />
                </td>
                <td className="col-md-5">
                  <Select
                    isMulti
                    options={niveaux}
                    style={{ textAlign: "center" }}
                    isSearchable={true}
                    onChange={e => addNiv(e, index)}
                  />
                </td>
                <td className="col-md-2" style={{ textAlign: "center" }}>
                  <i
                    style={{ color: "#cc0000", cursor: "pointer" }}
                    className="mdi mdi-delete font-size-18"
                    id="deletetooltip"
                    onClick={() => toggle(index)}
                  />
                </td>
              </tr>
            </tbody>
          ))}
        </table>
        <div style={{ display: "flex", justifyContent: "center" }}>
          {" "}
          <Col lg="6">
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
              className="text-center mt-4"
            >
              <Button
                type="button"
                color="warning"
                className="btn btn-warning  mb-2 me-2"
                onClick={props.back}
              >
                Annuler
              </Button>
            </div>
          </Col>
          <Col lg="6">
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
              }}
              className="text-center mt-4"
            >
              <button type="button" className="btn btn-primary " onClick={save}>
                CONFIRMER
              </button>
            </div>
          </Col>
        </div>
      </Row>
      <ToastContainer
        transition={Slide}
        enableMultiContainer
        containerId={"A"}
        position={toast.POSITION.TOP_RIGHT}
        autoClose={2500}
      />
      <Modal isOpen={modal} toggle={toggle} centered={true}>
        <ModalHeader toggle={toggle} tag="h4">
          {"Suppression d'une ligne"}{" "}
        </ModalHeader>
        <ModalBody>
          <div
            style={{
              fontFamily: "Montserrat, sans-serif",
              FontSize: "14px",
              FontWeight: "700",
              LineHeight: "18.375px",
            }}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              <p>Voulez-vous supprimer cette ligne ?</p>
            </div>
            <div
              className="hvr-push"
              style={{
                display: "flex",
                justifyContent: "space-around",
                marginBottom: "15px",
              }}
            >
              <div>
                <Button
                  onClick={toggleDelete}
                  color="info"
                  className="btn-rounded "
                >
                  <i
                    style={{ color: "white" }}
                    className="far fa-thumbs-up"
                  ></i>
                  Oui
                </Button>
              </div>
              <div>
                <Button
                  onClick={toggle}
                  color="danger"
                  className="btn-rounded "
                >
                  <i
                    style={{ color: "white" }}
                    className="far fa-thumbs-down"
                  ></i>
                  Non
                </Button>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default AddSpecialite
AddSpecialite.propTypes = {
  history: PropTypes.object,
  back: PropTypes.object,
  setSection: PropTypes.object,
}
