import React, {useEffect, useState} from "react"
import Select from "react-select"
import API from "../../api"
import PropTypes from "prop-types"
// Toastify
import {ToastContainer, toast} from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import {Slide} from "react-toastify"
import {withTranslation} from "react-i18next"
import {Button, Col, Label, Row} from "reactstrap"
import {AvForm, AvField} from "availity-reactstrap-validation"
import DataTable from "react-data-table-component"
// Checkbox
import Checkbox from "rc-checkbox"
import "rc-checkbox/assets/index.css"

const AddUser = props => {
    const [disbutReg, setDisbutReg] = useState(true)
    const [loading, setLoading] = useState(false)
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [roles, setRoles] = useState([])
    const [selectRole, setSelectRole] = useState("")
    const [mycurrentPage, setMycurrentPage] = useState(1)
    const [resetFirstPage, setResetFirstPage] = useState(false)
    const [listmenu, setListmenu] = useState([])
    const [permissions, setPermissions] = useState([]);
    var userAuthScolarite = JSON.parse(localStorage.getItem("userAuthScolarite"))
    var annee_id = userAuthScolarite.user.annee_id
    useEffect(async () => {
        const res = await API.get("Role/select").then(res => {
            setRoles(res.data.Role)
        })
        API.get("Menu/list").then(resU => {
            setListmenu(resU.data.Menu)
        })
    }, [])
    const addPerm = (index, e, rowIndex, attribute) => {
        setPermissions((prevPermissions) => {
            const updatedPermissions = [...prevPermissions];
            const rowData = dataa[index].Detail[rowIndex];
            // Initialize the matrix for this index if it's not already defined
            if (!updatedPermissions[index]) {
                updatedPermissions[index] = [];
            }
            // Initialize the row for this rowIndex if it's not already defined
            if (!updatedPermissions[index][rowIndex]) {
                updatedPermissions[index][rowIndex] = {
                    sousmenu: rowData.props.children.props.data[0].id,
                    add: 0,
                    delete: 0,
                    edit: 0,
                    view: 0,
                    imp: 0,
                    excel: 0,
                };
            }
            // Update the attribute value for this combination of index and rowIndex
            updatedPermissions[index][rowIndex][attribute] = e.target.checked ? 1 : 0;
            return updatedPermissions;
            // console.log(updatedPermissions)
        });
    };
    const expandableRowsComponent = (row) => (
        <div>
            {row.data.Detail.map((el, i) => (
                <div key={i}>
                    {el}
                </div>
            ))}
        </div>
    );
    const dataa = listmenu.map((menu, index) => ({
        id: menu.menu_id,
        name: menu.menu,
        Detail: menu.sous_menu.map((sousmenu, i) => (
            <div key={i}>
                <DataTable
                    columns={[
                        {name: 'sousmenu', selector: 'sousmenu', sortable: true},
                        {name: 'ajouter', selector: 'ajouter', sortable: true},
                        {name: 'supprimer', selector: 'supprimer', sortable: true},
                        {name: 'modifier', selector: 'modifier', sortable: true},
                        {name: 'consulter', selector: 'consulter', sortable: true},
                        {name: 'imprimer', selector: 'imprimer', sortable: true},
                        {name: 'excel', selector: 'excel', sortable: true},

                    ]}
                    data={
                        [{
                            id: sousmenu.sous_menu_id,
                            sousmenu: sousmenu.sous_menu,
                            ajouter: (
                                <div key={i} style={{display: 'flex', justifyContent: 'center'}}>
                                    <Checkbox
                                        checked={permissions[index]?.[i]?.add === 1}
                                        onChange={(e) => addPerm(index, e, i, 'add')}/>


                                </div>
                            ),
                            supprimer: (
                                <div key={i} style={{display: 'flex', justifyContent: 'center'}}>
                                    <Checkbox
                                        checked={permissions[index]?.[i]?.delete === 1}
                                        onChange={(e) => addPerm(index, e, i, 'delete')}/>
                                </div>
                            ),
                            modifier: (
                                <div key={i} style={{display: 'flex', justifyContent: 'center'}}>
                                    <Checkbox
                                        checked={permissions[index]?.[i]?.edit === 1}
                                        onChange={(e) => addPerm(index, e, i, 'edit')}/>
                                </div>
                            ),
                            consulter: (
                                <div key={i} style={{display: 'flex', justifyContent: 'center'}}>
                                    <Checkbox
                                        checked={permissions[index]?.[i]?.view === 1}
                                        onChange={(e) => addPerm(index, e, i, 'view')}/>
                                </div>
                            ),
                            imprimer: (
                                <div key={i} style={{display: 'flex', justifyContent: 'center'}}>
                                    <Checkbox
                                        checked={permissions[index]?.[i]?.imp === 1}
                                        onChange={(e) => addPerm(index, e, i, 'imp')}/>
                                </div>
                            ),
                            excel: (
                                <div key={i} style={{display: 'flex', justifyContent: 'center'}}>
                                    <Checkbox
                                        checked={permissions[index]?.[i]?.excel === 1}
                                        onChange={(e) => addPerm(index, e, i, 'excel')}/>
                                </div>
                            )
                        }]
                    }
                    noHeader
                />
            </div>
        )),
    }));
    const renderChildRow = (row) => {
        return (
            <div>
                <p>Name: {row.name}</p>
                <p>Age: {row.age}</p>
                <p>Country: {row.country}</p>
            </div>
        );
    };
    const columns = [

        {name: 'Menu', selector: (row) => row.name},

    ];
    const save = async () => {
        if (name != "" && email != "" && password != "" ) {
            if(permissions.length >0){
                if (selectRole != "") {
                    setDisbutReg(false)
                    const res = await API.post("User/add", {
                        name,
                        email,
                        password,
                        role_id: selectRole.value,
                        permissions: permissions,
                        annee_id:annee_id
                    }).then(res => {
                        if(res.data.errNum==="422"){
                            toast.error("Cette Émail est déjà existé ", { containerId: "A",})
                        }else{
                            props.setSection(1)
                        }
                    })
                } else {
                    setDisbutReg(true)
                    toast.error("Veuillez selectionner un rôle", {
                        containerId: "A",
                    })
                }
            }else{
                toast.error("Il faut sélectionner à la moins une permission !!", { containerId: "A",})
            }


        }else{
            toast.error("Tous les champs sont obligatoires ", { containerId: "A",})
        }
    }
    const newElement = async (page, totalRows) => {
        setMycurrentPage(page)
        const res = await API.get("menu/list")
            .then(res => {
                setListmenu(res.data.Menu)
                console.log(listmenu)
                //setNbrTotlEl(res.data.Data[0].total)
            })
    }

    const perPage = async (currentRowsPerPage, currentPage) => {
        setMycurrentPage(currentPage)
        const res = await API.get("menu/list")
            .then(res => {
                setListmenu(res.data.Menu)
            })
    }


    return (
        <React.Fragment>
            <div>
                <Row>
                    <AvForm>
                        <Row>
                            <Col lg="6">
                                <div className="mb-3">
                                    <Label for="basicpill-firstname-input1">Nom</Label>
                                    <AvField
                                        name="nom"
                                        placeholder="Inserer un nom"
                                        type="text"
                                        errorMessage="* Nom obligatoire"
                                        className="form-control"
                                        onChange={e => setName(e.target.value)}
                                        validate={{required: {value: true}}}
                                    />
                                </div>
                            </Col>
                            <Col lg="6">
                                <Label for="basicpill-firstname-input1">Email</Label>
                                <AvField
                                    name="email"
                                    value={email}
                                    placeholder="Enter un email valide"
                                    type="email"
                                    onChange={e => setEmail(e.target.value)}
                                    errorMessage="* Email invalide"
                                    validate={{
                                        required: {value: true},
                                        email: {value: true},
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="6">
                                <Label for="basicpill-firstname-input1">Password</Label>
                                <AvField
                                    name="password"
                                    type="password"
                                    placeholder="Inserer un password"
                                    errorMessage="* Password obligatoire"
                                    validate={{required: {value: true}}}
                                    onChange={e => setPassword(e.target.value)}
                                />
                            </Col>
                            <Col lg="6">
                                <div className="mb-3">
                                    <Label for="basicpill-address-input1">Rôle</Label>
                                    <Select
                                        options={roles}
                                        isSearchable={true}
                                        classNamePrefix="select2-selection"
                                        onChange={setSelectRole}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <DataTable
                            title="Permissions"
                            columns={columns}
                            data={dataa}
                            expandableRows
                            expandableRowsComponent={expandableRowsComponent}
                            onChangeRowsPerPage={(currentRowsPerPage, currentPage) =>
                                perPage(currentRowsPerPage, currentPage)
                            }
                        />
                        <div style={{display: "flex", justifyContent: "center"}}>
                            {" "}
                            <Col lg="6">
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                    }}
                                    className="text-center mt-4"
                                >
                                    <Button
                                        type="button"
                                        color="warning"
                                        className="btn btn-warning  mb-2 me-2"
                                        onClick={props.back}
                                    >
                                        {props.t("Annuler")}
                                    </Button>
                                </div>
                            </Col>
                            <Col lg="6">
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "flex-start",
                                    }}
                                    className="text-center mt-4"
                                >
                                    <Button
                                        id="Confirmer"
                                        name="Confirmer"
                                        type="button"
                                        color="primary"
                                        className="btn btn-primary mb-2 me-2"
                                        disabled={!disbutReg}
                                        onClick={save}
                                    >
                                        Confirmer
                                    </Button>
                                </div>
                            </Col>
                        </div>
                    </AvForm>
                </Row>
            </div>
            <ToastContainer
                transition={Slide}
                enableMultiContainer
                containerId={"A"}
                position={toast.POSITION.TOP_RIGHT}
                autoClose={2500}
            />
        </React.Fragment>
    )
}

export default withTranslation()(AddUser)

AddUser.propTypes = {
    history: PropTypes.object,
    back: PropTypes.object,
    setSection: PropTypes.object,
    t: PropTypes.any,
}
