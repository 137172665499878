import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import ToolkitProvider, {Search} from "react-bootstrap-table2-toolkit"
import API from "../../api"

import {
    Row,
    Col,
    Input,
    Button,
    Progress,
    Modal,
    ModalBody, Label, Form,
} from "reactstrap"
import {withRouter} from "react-router"
// Toastify
import {ToastContainer, toast} from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import {Slide} from "react-toastify"
import Select from "react-select"

const ImportEnseignant = props => {
    const [modal, setModal] = useState(false)
    const [file, setFile] = useState(null)
    const [uploadPercent, setUploadPercent] = useState(0)
    const [departement, setDepartement] = useState([])
    const [selectDepartement, setSelectDepartement] = useState("")
    // Data Table
    const [orders, setOrders] = useState([])
    const [cloture, setCloture] = useState(0);

    const pageOptions = {
        loading: true,
        hidePageListOnlyOnePage: true,
        totalSize: orders.length,
        page: 1,
        nextPageText: "Next",
        prePageText: "Back",
        sizePerPage: 30,
        alwaysShowAllBtns: true,
        sizePerPageList: [
            {
                text: "5",
                value: 5,
            },
            {
                text: "10",
                value: 10,
            },
            {
                text: "20",
                value: 20,
            },
            {
                text: "30",
                value: 30,
            },
            {
                text: "All",
                value: orders.length,
            },
        ],
    }

    const NoDataIndication = () => (
        <h6 style={{textAlign: "center"}}>No record to display</h6>
    )

    const Columns = () => [
        {
            dataField: "code",
            text: "Code unique",
            style: {textAlign: "center"},
            headerStyle: (colum, colIndex) => {
                return {textAlign: "center"}
            },
        },
        {
            dataField: "Name",
            text: "Nom et prénom",
            style: {textAlign: "center"},
            headerStyle: (colum, colIndex) => {
                return {textAlign: "center"}
            },
        },
        {
            dataField: "grade",
            text: "Grade",
            style: {textAlign: "center"},
            headerStyle: (colum, colIndex) => {
                return {textAlign: "center"}
            },
        },
        {
            dataField: "departement",
            text: "Département",
            style: {textAlign: "center"},
            headerStyle: (colum, colIndex) => {
                return {textAlign: "center"}
            },
        },
    ]

    const {SearchBar} = Search

    useEffect(async () => {
        var cloture_annee = JSON.parse(localStorage.getItem("cloture"));
        setCloture(cloture_annee)
        const res = await API.get("enseignant/liste").then(res => {
            setOrders(res.data.enseignants)
        })
        const resD = await API.get("departement/select").then(resD => {
            setDepartement(resD.data.Departement)
        })
    }, [])

    const toggle = () => {
        if (selectDepartement == "") {
            toast.error("⛔ Veuillez choisir un Département", {
                containerId: "A",
            })
        } else {
            setModal(!modal)
            setUploadPercent(0)
        }

    }

    const addFile = e => {
        var file = e.target.files[0]
        setFile(file)
    }

    const uploadFile = async () => {
        let data = new FormData()
        data.append("file", file)
        data.append("departement_id", selectDepartement.value)
        const options = {
            onUploadProgress: progressEvent => {
                const {loaded, total} = progressEvent
                let percent = Math.floor((loaded * 100) / total)
                console.log(`${loaded}kb of ${total}kb | ${percent}%`)
                if (percent < 100) {
                    setUploadPercent(percent)
                }
            },
        }
        API.post("enseignant/import", data, options).then(res => {
            setUploadPercent(100)
            setModal(false)
            const resE = API.get("user/list_id_enseignant").then(resE => {
                const res = API.post("enseignant/list", {
                    comptes: resE.data.comptes,
                }).then(res => {
                    setOrders(res.data.enseignants)
                })
            })
        })
    }

    return (
        <React.Fragment>
            <div>
                <Row>
                    <Col xs="12">
                        <ToolkitProvider
                            keyField="id"
                            data={orders}
                            columns={Columns()}
                            search
                            bootstrap4
                        >
                            {toolkitProps => (
                                <div>
                                    <Form className="mt-4">

                                        <Row>
                                            <Col lg="6">
                                                <div className="mb-3">
                                                    <Label for="basicpill-firstname-input1">Département : </Label>
                                                    <Select
                                                        options={departement}
                                                        isSearchable={true}
                                                        onChange={e => setSelectDepartement(e)}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Form>
                                    <Row className="mb-2">
                                        <Col sm="4">
                                            <div className="search-box me-2 mb-2 d-inline-block">
                                                <div className="position-relative">
                                                    <SearchBar {...toolkitProps.searchProps} />
                                                    <i className="bx bx-search-alt search-icon"/>
                                                </div>
                                            </div>
                                        </Col>
                                        {cloture != 1 ? (
                                            <Col sm="8">
                                                <div className="text-sm-end">
                                                    <Button
                                                        type="button"
                                                        color="success"
                                                        className="btn mb-2 me-2"
                                                        onClick={toggle}
                                                    >
                                                        <i className="fas fa-file-import me-1"/>
                                                        Import
                                                    </Button>
                                                </div>
                                            </Col>
                                        ) : (
                                            <Col sm="8">
                                                <div className="text-sm-end">
                                                    <Button
                                                        type="button"
                                                        color="success"
                                                        className="btn mb-2 me-2"
                                                        disabled={true}
                                                    >
                                                        <i className="fas fa-file-import me-1"/>
                                                        Import
                                                    </Button>
                                                </div>
                                            </Col>
                                        )}
                                    </Row>
                                    <BootstrapTable
                                        wrapperClasses="table-responsive"
                                        noDataIndication={() => <NoDataIndication/>}
                                        striped={false}
                                        bordered={false}
                                        classes={"table align-middle table-nowrap"}
                                        headerWrapperClasses={"table-light"}
                                        hover
                                        pagination={paginationFactory(pageOptions)}
                                        {...toolkitProps.baseProps}
                                    />
                                </div>
                            )}
                        </ToolkitProvider>
                    </Col>
                </Row>
            </div>
            <ToastContainer
                transition={Slide}
                enableMultiContainer
                containerId={"A"}
                position={toast.POSITION.TOP_RIGHT}
                autoClose={2500}
            />
            <Modal isOpen={modal} toggle={toggle} centered={true}>
                <div
                    className="modal-header"
                    style={{
                        width: "100% !important",
                        justifyContent: "center !important",
                        margin: "0 auto",
                        fontSize: "20px",
                        color: "#556ee6",
                    }}
                    toggle={toggle}
                >
                    Importation Liste Enseignant
                </div>
                <ModalBody>
                    <div
                        style={{
                            fontFamily: "Montserrat, sans-serif",
                            FontSize: "14px",
                            FontWeight: "700",
                            LineHeight: "18.375px",
                        }}
                    >
                        {uploadPercent <= 0 ? (
                            <Input
                                onChange={e => addFile(e)}
                                className="form-control mt-4"
                                type="file"
                                id="formFile"
                            />
                        ) : null}
                        {uploadPercent > 0 && uploadPercent < 100 ? (
                            <Progress
                                striped
                                className="progress-xl"
                                color="success"
                                value={uploadPercent}
                            >
                                {uploadPercent}%
                            </Progress>
                        ) : null}
                        {uploadPercent == 100 ? (
                            <div style={{display: "flex", justifyContent: "center"}}>
                                Success
                                <i
                                    style={{
                                        paddingLeft: "5px",
                                        color: "darkcyan",
                                        paddingTop: "3.5px",
                                    }}
                                    className="fas fa-check me-1"
                                />
                            </div>
                        ) : null}
                        {uploadPercent != 100 ? (
                            <div style={{display: "flex", justifyContent: "center"}}>
                                <Button
                                    type="button"
                                    color="success"
                                    className="btn mb-2 me-2 mt-4"
                                    onClick={uploadFile}
                                >
                                    <i className="fas fa-cloud-upload-alt me-1"/>
                                    Upload
                                </Button>
                            </div>
                        ) : null}
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment>
    )
}

export default withRouter(ImportEnseignant)
ImportEnseignant.propTypes = {
    orders: PropTypes.array,
    history: PropTypes.object,
}
