import React, { useState, useEffect, useMemo } from "react"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"
import { withRouter } from "react-router-dom"
import PropTypes from "prop-types"
import API from "../../api"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import {
  Button,
  Col,
  Form,
  Input,
  Label,
  Row,
  Card,
  CardBody,
  CardTitle,
  Container,
  Spinner,
} from "reactstrap"

const EditSemstre = props => {
  const [nom, setNom] = useState("")
  const [dateDebut, setDateDebut] = useState("");
  const [dateFin, setDateFin] = useState("");
  const [loading, setLoading] = useState(false)
  var userAuthScolarite = JSON.parse(localStorage.getItem("userAuthScolarite"))
  var annee_id = userAuthScolarite.user.annee_id
  useEffect(async () => {
    var url = window.location.href
    var array = url.split("=")
    var id = array[1]
    const res = await API.post("semestre/get_by_id", { id }).then(res => {
      setNom(res.data.Semestre.name)
      setDateDebut(new Date(res.data.Semestre.date_debut))
      setDateFin(new Date(res.data.Semestre.date_fin))
      setLoading(true)
    })
  }, [])

  const edit = async () => {
    var url = window.location.href
    var array = url.split("=")
    var id = array[1]
    //date_debut
    if (dateDebut != "") {
      let dateComm = dateDebut
      let month = "" + (dateComm.getMonth() + 1)
      let day = "" + dateComm.getDate()
      let year = dateComm.getFullYear()
      if (month.length < 2) month = "0" + month
      if (day.length < 2) day = "0" + day
      var convertDate = [year, month, day].join("-")
    } else {
      var convertDate = ""
    }
    //date_fin
    if (dateFin != "") {
      let dateCommFin = dateFin
      let month_fin = "" + (dateCommFin.getMonth() + 1)
      let day_fin = "" + dateCommFin.getDate()
      let year_fin = dateCommFin.getFullYear()
      if (month_fin.length < 2) month_fin = "0" + month_fin
      if (day_fin.length < 2) day_fin = "0" + day_fin
      var convertDateFin = [year_fin, month_fin, day_fin].join("-")
    } else {
      var convertDateFin = ""
    }

    const res = await API.post("semestre/update", {
      id: id,
      name: nom,
      date_debut:convertDate,
      date_fin:convertDateFin,
      annee_id
    })
      .then(res => {
        props.history.push("/Semestre")
      })
      .catch(() => {
        toast.error("⛔ Problème lors de l'insertion !", {
          containerId: "A",
        })
      })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Card>
              <CardBody>
                {loading ? (
                  <Row>
                    <Form>
                      <Row>
                        <Col lg="6">
                          <div className="mb-3">
                            <Label for="basicpill-firstname-input1">
                              Nom  :
                            </Label>
                            <Input
                              lg="3"
                              className="form-control glyphicon glyphicon-star"
                              placeholder="InstitutOrigin"
                              type="text"
                              value={nom}
                              onChange={e => setNom(e.target.value)}
                            />
                          </div>
                        </Col>
                        <Col lg="3">
                          <div className="mb-3">
                            <Label for="basicpill-firstname-input1">
                              {"Date Début "}
                            </Label>
                            <Col md={12} className="pr-0">
                              <DatePicker
                                  className="form-control ddate"
                                  selected={dateDebut}
                                  onChange={e => setDateDebut(e)}
                                  dateFormat="dd/MM/yyyy"
                              />
                            </Col>
                          </div>
                        </Col>
                        <Col lg="3">
                          <div className="mb-3">
                            <Label for="basicpill-firstname-input1">
                              {"Date Fin "}
                            </Label>
                            <Col md={12} className="pr-0">
                              <DatePicker
                                  className="form-control ddate"
                                  selected={dateFin}
                                  onChange={e => setDateFin(e)}
                                  dateFormat="dd/MM/yyyy"
                              />
                            </Col>
                          </div>
                        </Col>
                      </Row>
                    </Form>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      {" "}
                      <Col lg="6">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                          className="text-center mt-4"
                        >
                          <Button
                            type="button"
                            color="warning"
                            className="btn btn-warning  mb-2 me-2"
                            onClick={() => props.history.push("/Semestre")}
                          >
                            Annuler
                          </Button>
                        </div>
                      </Col>
                      <Col lg="6">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                          }}
                          className="text-center mt-4"
                        >
                          <button
                            type="button"
                            className="btn btn-primary "
                            onClick={edit}
                          >
                            CONFIRMER
                          </button>
                        </div>
                      </Col>
                    </div>
                  </Row>
                ) : (
                  <div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {" "}
                      <Spinner type="grow" className="ms-6" color="primary" />
                    </div>
                    <h4
                      style={{ textAlign: "center", marginTop: "2%" }}
                      className="ms-6"
                    >
                      {" "}
                      {"Chargement"} ...{" "}
                    </h4>
                  </div>
                )}
              </CardBody>
            </Card>
          </Row>
          <ToastContainer
            transition={Slide}
            enableMultiContainer
            containerId={"A"}
            position={toast.POSITION.TOP_RIGHT}
            autoClose={2500}
          />
        </Container>
      </div>
    </React.Fragment>
  )
}
export default withRouter(EditSemstre)
EditSemstre.propTypes = {
  history: PropTypes.object,
}
